import Container from "react-bootstrap/Container";
import { Row, Table, Button } from "react-bootstrap";
import { CarList } from "../../components/CarList";
import "../../veiling-buyer.css";
import { Spinner } from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function WonAuctionsPage(props) {
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const token = apiAccessToken;
  const user = userMetadata;
  const [auctions, setAuctions] = useState(null);
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");
  const [totalWonAuctions, setTotalWonAuctions] = useState(0);
  const [highestAuction, setHighestAuction] = useState(null);
  const [highestBid, setHighestBid] = useState(null);
  const [showStats, setShowStats] = useState(false);

  useEffect(() => {
    const titleOptions = [t("won_title"), t("USELL")];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    if (!user?.id || !token) return;

    const URL = `https://usell.be/api/winning-auctions/${user.id}/`;
    const options = {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    fetchData();

    async function fetchData() {
      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log(data);
        const completedAuctions = data.filter(
          (item) =>
            item.winning_bid &&
            ["COMPLETED", "FINISHED", "PAID"].includes(item.auction.status)
        );
        setAuctions(completedAuctions.map((item) => item.auction));
        setTotalWonAuctions(completedAuctions.length);
        if (completedAuctions.length > 0) {
          const highestBidAuction = completedAuctions.reduce((prev, current) =>
            prev.winning_bid.amount > current.winning_bid.amount
              ? prev
              : current
          );
          setHighestBid(highestBidAuction.bid.amount);
          setHighestAuction(highestBidAuction.auction);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [user.id, token]);

  if (!auctions) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <UserNavigation />
      <motion.div
        className="won-hero"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="won-background"></div>
        <Container className="content content-padding-top-lg content-padding-bottom-lg">
          <motion.h1
            className="winning-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {dynamicTitle}
          </motion.h1>
          <motion.h4
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            {t("won_text")}
          </motion.h4>
        </Container>
      </motion.div>
      <Container className="content veilingenpagina">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 10 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2,
              },
            },
          }}
        >
          <Row>
            <CarList auctions={auctions} following={true} />
          </Row>
        </motion.div>
      </Container>

      <div
        className="stats-button-container space-under"
        style={{ textAlign: "center", marginTop: "20px" }}
      >
        <Button variant="primary" onClick={() => setShowStats(!showStats)}>
          {showStats ? "Verberg Statistieken" : "Bekijk Statistieken"}
        </Button>
      </div>

      {showStats && (
        <Container className="stats-container">
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Aantal gewonnen veilingen</td>
                <td>{totalWonAuctions}</td>
              </tr>
              {highestAuction && highestBid ? (
                <>
                  <tr>
                    <td>Hoogste bod</td>
                    <td>€{highestBid}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td colSpan="2">Geen geldige hoogste veiling gevonden.</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      )}
    </>
  );
}
