import React, { useState, useContext, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";

export function CompleteProfilePage() {
  const { userMetadata, setUserMetadata, apiAccessToken } =
    useContext(AuthContext);
  const [profile, setProfile] = useState(
    userMetadata || JSON.parse(sessionStorage.getItem("userMetadata"))
  );
  const [error, setError] = useState(null);
  const [ageError, setAgeError] = useState(null);
  const [consentError, setConsentError] = useState(null);
  const [vatValidation, setVatValidation] = useState(null);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const { t } = useTranslation();

  const handleOpenModal = () => {
    if (
      !profile.gdpr_consent ||
      !profile.privacy_policy_consent ||
      !profile.terms_of_service_consent
    ) {
      setConsentError(
        "U moet akkoord gaan met alle voorwaarden en beleidsregels om door te gaan."
      );
      return;
    }
    setShowAgreementModal(true);
  };

  const handleConfirmSubmit = (e) => {
    setShowAgreementModal(false);
    handleSubmit(e);
  };

  const checkProfileCompleteness = (userMetadata) => {
    return (
      userMetadata.first_name &&
      userMetadata.last_name &&
      userMetadata.birthdate &&
      userMetadata.type &&
      userMetadata.address_id
    );
  };

  useEffect(() => {
    if (userMetadata && checkProfileCompleteness(userMetadata)) {
      window.location.href = "/dashboard/profiel";
    }
  }, [userMetadata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const snakeCaseName = name.replace(/([A-Z])/g, "_$1").toLowerCase();
    setProfile({ ...profile, [snakeCaseName]: value });

    if (name === "vat_number" && value) {
      validateVAT(value);
    }

    if (name === "birthdate") {
      const today = new Date();
      const birthDate = new Date(value);

      const yearDiff = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      const dayDiff = today.getDate() - birthDate.getDate();

      console.log("Today's date:", today);
      console.log("Birthdate:", birthDate);
      console.log("Year difference:", yearDiff);
      console.log("Month difference:", monthDiff);
      console.log("Day difference:", dayDiff);

      if (
        yearDiff < 18 ||
        (yearDiff < 18 && monthDiff > 0) ||
        (yearDiff < 18 && dayDiff > 0) ||
        (yearDiff === 18 && monthDiff > 0) ||
        (yearDiff === 18 && dayDiff > 0 && monthDiff === 0)
      ) {
        setAgeError("You must be at least 18 years old.");
      } else {
        setAgeError(null);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProfile({ ...profile, [name]: checked });

    if (name === "is_company" || name === "is_cardealer") {
      if (!checked) {
        setProfile((prev) => ({
          ...prev,
          vat_number: "",
          type: "seller",
        }));
      } else {
        const newType = name === "is_company" ? "company" : "buyer";
        setProfile((prev) => ({ ...prev, type: newType }));
      }
    }
  };

  const validateVAT = async (vatNumber) => {
    try {
      const response = await fetch("https://usell.be/api/validate-vat/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiAccessToken}`,
        },
        body: JSON.stringify({ vat_number: vatNumber }),
      });

      const result = await response.json();
      if (response.ok) {
        setVatValidation({ valid: true, message: "Geldig BTW-nummer" });
      } else {
        setVatValidation({
          valid: false,
          message: result.message || "Ongeldig BTW-nummer",
        });
      }
    } catch (error) {
      setVatValidation({
        valid: false,
        message: "Er ging iets mis met de validatie.",
      });
    }
  };

  const fetchUpdatedUser = async () => {
    try {
      const response = await fetch(
        `https://usell.be/api/user/${profile.Authsub}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiAccessToken}`,
          },
        }
      );

      if (!response.ok) {
        console.error("Failed to fetch updated user data");
        return;
      }

      const updatedUser = await response.json();

      if (!updatedUser.id) {
        console.error("Updated user data is missing the `id` field");
        return;
      }

      setUserMetadata(updatedUser);
      sessionStorage.setItem("userMetadata", JSON.stringify(updatedUser));
      sessionStorage.setItem("needsRefresh", "true");
      if (checkProfileCompleteness(updatedUser)) {
        window.location.reload();
      }
    } catch (error) {
      console.error("Error fetching updated user data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setConsentError(null);
    console.log(profile);

    /*if (!profile.gdpr_consent || !profile.privacy_policy_consent || !profile.terms_of_service_consent) {
            setConsentError("U moet akkoord gaan met alle voorwaarden en beleidsregels om door te gaan.");
            return;
        }*/

    if (ageError || (vatValidation && !vatValidation.valid)) {
      setError("Er zijn ongeldige velden. Controleer uw gegevens.");
      return;
    }

    const address = {
      street: profile.street,
      house_number: profile.house_number,
      zip_code: profile.zip_code,
      city: profile.city,
      province: profile.province,
      country: profile.country,
      phone_number: profile.phone_number,
    };

    try {
      const addressResponse = await fetch(
        `https://usell.be/api/address/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiAccessToken}`,
          },
          body: JSON.stringify(address),
        }
      );

      if (!addressResponse.ok) {
        const errorText = await addressResponse.text();
        throw new Error(errorText);
      }

      const newAddress = await addressResponse.json();

      const updatedUser = {
        id: profile.user_id || profile.id,
        Authsub: profile.Authsub,
        first_name: profile.first_name,
        last_name: profile.last_name,
        email: profile.email,
        birthdate: profile.birthdate,
        address_id: newAddress.id,
        profile_picture: profile.profile_picture,
        status: profile.status,
        website: profile.website,
        gdpr_consent: profile.gdpr_consent,
        privacy_policy_consent: profile.privacy_policy_consent,
        terms_of_service_consent: profile.terms_of_service_consent,
        is_company: profile.is_company,
      };

      if (profile.is_company && profile.vat_number) {
        updatedUser.vat_number = profile.vat_number;
        updatedUser.type = "company";
      } else if (!profile.is_company && profile.vat_number) {
        updatedUser.vat_number = profile.vat_number;
        updatedUser.type = "buyer";
      } else {
        updatedUser.type = "seller";
      }

      if (!profile.user_id) {
        console.error("Gelieve uw email te bevestigen");
        setError(
          "Uw email is nog niet bevestigd, gelieve deze te vervolledigen."
        );
        return;
      }

      const userResponse = await fetch(
        `https://usell.be/api/update-user/${profile.user_id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiAccessToken}`,
          },
          body: JSON.stringify(updatedUser),
        }
      );

      if (!userResponse.ok) {
        const errorText = await userResponse.text();
        throw new Error(errorText);
      }

      await fetchUpdatedUser();

      window.location.reload();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setError(error.message);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={8}>
          <h2>{t("complete_profile1")}</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile2")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={profile.first_name}
                    onChange={handleChange}
                    placeholder={t("complete_profile2")}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile3")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="last_name"
                    value={profile.last_name}
                    onChange={handleChange}
                    placeholder={t("complete_profile3")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile4")}</Form.Label>
                  <Form.Control
                    type="date"
                    name="birthdate"
                    value={profile.birthdate}
                    onChange={handleChange}
                    placeholder="Geboortedatum"
                    required
                  />
                </Form.Group>
                {ageError && <div className="text-danger">{ageError}</div>}
              </Col>
              <Col md={6}>
                <Form.Group controlId="companyCheckbox">
                  <Form.Label>{t("complete_profile15")}</Form.Label>
                  <Form.Check
                    type="checkbox"
                    name="is_company"
                    label={t("complete_profile16")}
                    checked={profile.is_company || false}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Check
                    type="checkbox"
                    name="is_cardealer"
                    label={t("complete_profile17")}
                    checked={profile.is_cardealer || false}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                {(profile.is_company || profile.is_cardealer) && (
                  <Form.Group>
                    <Form.Label>{t("complete_profile5")}</Form.Label>
                    <Form.Control
                      type="text"
                      name="vat_number"
                      value={profile.vat_number}
                      onChange={handleChange}
                      placeholder={t("complete_profile5")}
                    />
                    {vatValidation && (
                      <div
                        className={
                          vatValidation.valid ? "text-success" : "text-danger"
                        }
                      >
                        {vatValidation.message}
                      </div>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile7")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="street"
                    value={profile.street}
                    onChange={handleChange}
                    placeholder={t("complete_profile7")}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile8")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="house_number"
                    value={profile.house_number}
                    onChange={handleChange}
                    placeholder={t("complete_profile8")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile9")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    value={profile.zip_code}
                    onChange={handleChange}
                    placeholder={t("complete_profile9")}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile10")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={profile.city}
                    onChange={handleChange}
                    placeholder={t("complete_profile10")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile11")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="province"
                    value={profile.province}
                    onChange={handleChange}
                    placeholder={t("complete_profile11")}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label>{t("complete_profile12")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={profile.country}
                    onChange={handleChange}
                    placeholder={t("complete_profile12")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group>
                  <Form.Label>{t("complete_profile13")}</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone_number"
                    value={profile.phone_number}
                    onChange={handleChange}
                    placeholder={t("complete_profile13")}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group controlId="gdprConsent">
                  <Form.Check
                    type="checkbox"
                    name="gdpr_consent"
                    label={t("complete_profile18")}
                    checked={profile.gdpr_consent}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="privacyPolicyConsent">
                  <Form.Check
                    type="checkbox"
                    name="privacy_policy_consent"
                    label={t("complete_profile19")}
                    checked={profile.privacy_policy_consent}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="termsOfServiceConsent">
                  <Form.Check
                    type="checkbox"
                    name="terms_of_service_consent"
                    label={t("complete_profile20")}
                    checked={profile.terms_of_service_consent}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            {consentError && <div className="text-danger">{consentError}</div>}
            <Row>
              <Col md={12}>
                <Button variant="primary" onClick={handleOpenModal}>
                  {t("complete_profile14")}
                </Button>
              </Col>
            </Row>
            {error && <div className="text-danger">{error}</div>}
          </Form>
        </Col>
      </Row>
      <Modal
        show={showAgreementModal}
        onHide={() => setShowAgreementModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Belangrijke mededeling")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t(
              "U moet akkoord gaan met de gebruikersvoorwaarden om door te gaan met de registratie."
            )}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAgreementModal(false)}
          >
            {t("Ik ga niet akkoord")}
          </Button>
          <Button variant="primary" onClick={handleConfirmSubmit}>
            {t("Ik ga akkoord")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
