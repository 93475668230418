import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FAQ from "../components/FAQ";

export function FaqPage() {
  const { t } = useTranslation();

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    setFaqs([
      { question: t("faq_usell"), answer: t("faq_usell_answer"), open: false },
      {
        question: t("faq_veiling"),
        answer: t("faq_veiling_answer"),
        open: false,
      },
      {
        question: t("faq_kosten"),
        answer: t("faq_kosten_answer"),
        open: false,
      },
      {
        question: t("faq_niet_verkocht"),
        answer: t("faq_niet_verkocht_answer"),
        open: false,
      },
      {
        question: t("faq_waarde_auto"),
        answer: t("faq_waarde_auto_answer"),
        open: false,
      },
      {
        question: t("faq_klantenservice"),
        answer: t("faq_klantenservice_answer"),
        open: false,
      },
      {
        question: t("faq_welke_wagens"),
        answer: t("faq_welke_wagens_answer"),
        open: false,
      },
      {
        question: t("faq_schade"),
        answer: t("faq_schade_answer"),
        open: false,
      },
      {
        question: t("faq_meerdere_wagens"),
        answer: t("faq_meerdere_wagens_answer"),
        open: false,
      },
      {
        question: t("faq_bedrijfswagens"),
        answer: t("faq_bedrijfswagens_answer"),
        open: false,
      },
      {
        question: t("faq_bod_weigeren"),
        answer: t("faq_bod_weigeren_answer"),
        open: false,
      },
      {
        question: t("faq_verkoop_verzekerd"),
        answer: t("faq_verkoop_verzekerd_answer"),
        open: false,
      },
      {
        question: t("faq_auto_keuren"),
        answer: t("faq_auto_keuren_answer"),
        open: false,
      },
      {
        question: t("faq_herroepingsrecht"),
        answer: t("faq_herroepingsrecht_answer"),
        open: false,
      },
      {
        question: t("faq_aanmelden_probleem"),
        answer: t("faq_aanmelden_probleem_answer"),
        open: false,
      },
      {
        question: t("faq_betalingen_veilig"),
        answer: t("faq_betalingen_veilig_answer"),
        open: false,
      },
      {
        question: t("faq_transactie_niet_direct"),
        answer: t("faq_transactie_niet_direct_answer"),
        open: false,
      },
      {
        question: t("faq_prijsonderhandelingen"),
        answer: t("faq_prijsonderhandelingen_answer"),
        open: false,
      },
      {
        question: t("faq_privacy"),
        answer: t("faq_privacy_answer"),
        open: false,
      },
      {
        question: t("faq_thuisadres"),
        answer: t("faq_thuisadres_answer"),
        open: false,
      },
      {
        question: t("faq_professionals_betrouwbaar"),
        answer: t("faq_professionals_betrouwbaar_answer"),
        open: false,
      },
    ]);
  }, [t]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => ({
        ...faq,
        open: i === index ? !faq.open : false,
      }))
    );
  };

  return (
    <div className="container">
      <h3 className="pt-3">{t("footerFAQ")}</h3>
      <div className="row">
        <div className="faqs row pt-3">
          {faqs.map((faq, index) => (
            <div className="col-6 my-3" key={index}>
              <FAQ faq={faq} index={index} toggleFAQ={toggleFAQ} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
