import React, { useEffect, useState, useContext } from "react";
import { Container, Table, Button, Spinner, Modal, Alert } from "react-bootstrap";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { faEuroSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

export function MyStockBidsPage() {
  const [forsaleStocks, setForsaleStocks] = useState([]);
  const [endedStocks, setEndedStocks] = useState([]);
  const [loadingBidId, setLoadingBidId] = useState(null);
  const [loadingRelistId, setLoadingRelistId] = useState(null);
  const [cancelledStocks, setCancelledStocks] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const [reloadBids, setReloadBids] = useState(false);
  const user = userMetadata;
  const token = apiAccessToken;
  const { t } = useTranslation();

  useEffect(() => {
    const fetchForsaleStocks = async () => {
      if (!user?.id || !token) return;

      const URL = `https://usell.be/api/stock/forsale-stock/${user.id}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        setForsaleStocks(data);
      } catch (error) {
        console.error("Error fetching forsale stock:", error);
      }
    };

    const fetchEndedStocks = async () => {
      if (!user?.id || !token) return;

      const URL = `https://usell.be/api/stock/forsale-stock-ended/${user.id}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        setEndedStocks(data);
      } catch (error) {
        console.error("Error fetching ended stock:", error);
      }
    };

    const fetchCancelledStocks = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/stock/forsale-stock-cancelled/${user.id}/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };
      try {
        const response = await fetch(URL, options);
        if (!response.ok) throw new Error("HTTP ERROR: " + response.status);
        const data = await response.json();
        setCancelledStocks(data);
      } catch (error) {
        console.error("Error fetching cancelled stock:", error);
      }
    };

    fetchForsaleStocks();
    fetchCancelledStocks();
    fetchEndedStocks();
  }, [token, user, reloadBids]);

  

  const handleBidStock = async (stockItemId, action) => {
    setLoadingBidId(stockItemId);
    const url = `https://usell.be/api/stock/${stockItemId}/${action}-bid/`;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(url, options);

      if (response.status === 302) {
        const data = await response.json();
        if (data.onboarding_url) {
          window.location.href = data.onboarding_url;
          return;
        }
      }

      if (!response.ok) throw new Error(`Failed to ${action} bid`);

      setTimeout(() => {
        setForsaleStocks((prev) =>
          prev.filter((stockItem) => stockItem.id !== stockItemId)
        );
        setLoadingBidId(null);
        if (action === "accept") {
          setSuccessMessage("Bod succesvol geaccepteerd");
        } else if (action === "decline") {
          setSuccessMessage("Bod succesvol geweigerd");
        }
        setReloadBids(prev => !prev);
      }, 1500);
    } catch (error) {
      console.error(`Error ${action} bid:`, error);
      setLoadingBidId(null);
    }
  };

  const handleRelistClick = (stockItem) => {
    setSelectedStock(stockItem);
    setShowModal(true);
  };

  const handleRelistStock = async () => {
    if (!selectedStock) return;
    const stockItemId = selectedStock.id;
    const url = `https://usell.be/api/relist-stock/${stockItemId}/`;
    const options = {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      setLoadingRelistId(stockItemId);
      const response = await fetch(url, options);
      console.log("Response status:", response.status);

      let responseData;
      try {
        responseData = await response.json();
      } catch (jsonError) {
        console.error("Fout bij JSON-parsen:", jsonError);
        throw new Error("Ongeldige serverresponse");
      }

      console.log("Backend response:", responseData);

      if (!response.ok) {
        throw new Error(
          responseData.error || responseData.message || t("relistError")
        );
      }

      setCancelledStocks((prev) =>
        prev.filter((stockItem) => stockItem.id !== stockItemId)
      );
      setSuccessMessage("Stock kavel succesvol opnieuw actief geplaatst");
      setReloadBids(prev => !prev);
    } catch (error) {
      console.error("Relist error:", error.message);
    } finally {
      setLoadingRelistId(null);
      setShowModal(false);
    }
  };

  return (
    <>
      <UserNavigation />
      <motion.div
        className="appointments-hero"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className={"appointments-background"}></div>
        <Container
          className={"content content-padding-top-lg content-padding-bottom-lg"}
        >
          <motion.h1
            className="appointments-title"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            {t("bid")}
          </motion.h1>
        </Container>
      </motion.div>
      <section className="bids-background">
        <Container>
          {successMessage && (
            <Alert
              variant="success"
              onClose={() => setSuccessMessage("")}
              dismissible
            >
              {successMessage}
            </Alert>
          )}
          <motion.h2
            className="mb-4 shadow-text stock-bids-titel"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {t("bid10")}
          </motion.h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("bid11")}</th>
                <th>{t("bid4")}</th>
                <th>{t("bid5")}</th>
              </tr>
            </thead>
            <motion.tbody initial="hidden" animate="visible">
              {forsaleStocks &&
                forsaleStocks.map((auction) => (
                  <motion.tr
                    key={auction.id}
                    className={loadingBidId === auction.id ? "loading-bid" : ""}
                  >
                    <td>{auction.name}</td>
                    <td>
                      <FontAwesomeIcon icon={faEuroSign} />
                      {auction.highest_bid ? auction.highest_bid : t("bid6")}
                    </td>
                    <td>
                      {auction.highest_bid ? (
                        <>
                          {loadingBidId === auction.id && (
                            <div className="loading-overlay">
                              <Spinner animation="border" />
                            </div>
                          )}
                          <Button
                            variant="success"
                            onClick={() => handleBidStock(auction.id, "accept")}
                            disabled={loadingBidId === auction.id}
                            className="stock-success-button"
                          >
                            {t("bid7")}
                          </Button>
                          <Button
                            variant="danger"
                            className="ms-2"
                            onClick={() =>
                              handleBidStock(auction.id, "decline")
                            }
                            disabled={loadingBidId === auction.id}
                          >
                            {t("bid8")}
                          </Button>
                        </>
                      ) : (
                        <span></span>
                      )}
                    </td>
                  </motion.tr>
                ))}
            </motion.tbody>
          </Table>

          <motion.h2
            className="mt-5 mb-4 shadow-text stock-bids-titel"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {t("bid12")}
          </motion.h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("bid11")}</th>
                <th>{t("bid4")}</th>
                <th>{t("bid5")}</th>
              </tr>
            </thead>
            <motion.tbody initial="hidden" animate="visible">
              {endedStocks &&
                endedStocks.map((auction) => (
                  <motion.tr
                    key={auction.id}
                    className={loadingBidId === auction.id ? "loading-bid" : ""}
                  >
                    <td>{auction.name}</td>
                    <td>
                      <FontAwesomeIcon icon={faEuroSign} />
                      {auction.highest_bid ? auction.highest_bid : t("bid6")}
                    </td>
                    <td>
                      {auction.highest_bid ? (
                        <>
                          {loadingBidId === auction.id && (
                            <div className="loading-overlay">
                              <Spinner animation="border" />
                            </div>
                          )}
                          <Button
                            variant="success"
                            onClick={() => handleBidStock(auction.id, "accept")}
                            disabled={loadingBidId === auction.id}
                          >
                            {t("bid7")}
                          </Button>
                          <Button
                            variant="danger"
                            className="ms-2"
                            onClick={() =>
                              handleBidStock(auction.id, "decline")
                            }
                            disabled={loadingBidId === auction.id}
                          >
                            {t("bid8")}
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="warning"
                          onClick={() => {
                            handleRelistClick(auction);
                          }}
                          disabled={loadingBidId === auction.id}
                        >
                          {t("Herplaats")}
                        </Button>
                      )}
                    </td>
                  </motion.tr>
                ))}
            </motion.tbody>
          </Table>
          <motion.h2
            className="mb-4 shadow-text stock-bids-titel"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            {t("Mijn Geannuleerde Stock")}
          </motion.h2>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("bid11")}</th>
                <th>{t("Status")}</th>
                <th>{t("bid5")}</th>
              </tr>
            </thead>
            <motion.tbody initial="hidden" animate="visible">
              {cancelledStocks &&
                cancelledStocks.map((auction) => (
                  <motion.tr
                    key={auction.id}
                    className={
                      loadingRelistId === auction.id ? "loading-bid" : ""
                    }
                  >
                    <td>{auction.name}</td>
                    <td>{t("Geannuleerd")}</td>
                    <td>
                      {loadingRelistId === auction.id ? (
                        <Spinner animation="border" />
                      ) : (
                        <Button
                          variant="warning"
                          onClick={() => handleRelistClick(auction)}
                          disabled={loadingRelistId === auction.id}
                        >
                          {t("Herplaats")}
                        </Button>
                      )}
                    </td>
                  </motion.tr>
                ))}
            </motion.tbody>
          </Table>
        </Container>
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{t("confirmRelist")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedStock && (
              <p>
                {t("confirmRelistMessage", { auctionName: selectedStock.name })}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              {t("cancel")}
            </Button>
            <Button variant="primary" onClick={handleRelistStock}>
              {t("confirm")}
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </>
  );
}
