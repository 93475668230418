import React from 'react';
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

export function Footer() {
    const { t } = useTranslation();

    return (
        <div className="footer" style={{ position: "relative", color: 'white' }}>
            <div className="footer-background-image"></div>
            <Container>
                <Row className={"align-items-start d-flex justify-content-center"} style={{ margin: "0rem" }}>
                    <Col sm={12} md={6} lg={3} className={"float-sm-left align-items-start d-flex justify-content-center"}>
                        <motion.img
                            className={"footer-logo-white"}
                            src="/images/logo_white.png"
                            alt={"usell logo"}
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        />
                    </Col>
                    <Col sm={12} md={6} lg={3} className={"float-sm-start align-items-start d-flex justify-content-lg-center"}>
                        <motion.ul
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.1 }}
                        >
                            <li className={"text-secondary"}><h5>{t('footer1')}</h5></li>
                            <li><Link to={"/"}>{t('footer2')}</Link></li>
                            <li><Link to={"/over-ons"}>{t('footer3')}</Link></li>
                            <li><Link to={"/contact"}>{t('footer4')}</Link></li>
                            <li><Link to={"/faq"}>{t('footerFAQ')}</Link></li>
                        </motion.ul>
                    </Col>
                    <Col sm={12} md={6} lg={3} className={"float-sm-start align-items-start d-flex justify-content-lg-center"}>
                        <motion.ul
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            <li className={"text-secondary"}><h5>{t('footer5')}</h5></li>
                            <li>Dorpsstraat 111 bus 2,</li>
                            <li>2221 Heist-op-den-Berg</li>
                            <li><a href="tel:+32493330819">+32 493/33.08.19</a></li>
                            <li><a href="mailto:info@usell.be">info@usell.be</a></li>
                            <li>BE 0764.545.783</li>
                        </motion.ul>
                    </Col>
                    <Col sm={12} md={6} lg={3} className={"float-sm-start align-items-start d-flex justify-content-lg-center"}>
                        <motion.ul
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.3 }}
                        >
                            <li className={"text-secondary"}><h5>{t('footer6')}</h5></li>
                            <li><Link to={"/legal"}>{t('footer7')}</Link></li>
                            <li><Link to={"/contacteer-ons"}>{t('footer8')}</Link></li>
                            <li><Link to={"https://cdn.cookiecode.nl/privacy/usell.be/nl/pdf"}>{t('footer9')}</Link></li>
                            <li><Link to={"https://cdn.cookiecode.nl/cookie/usell.be/nl/pdf"}>{t('footer10')}</Link></li>
                            
                        </motion.ul>
                    </Col>
                </Row>
                <Row>
                    <Container>
                        <hr className={"text-secondary opacity-75"} />
                    </Container>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={10}>
                        <motion.p
                            className={"text-secondary"}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            {t('footer_text')}
                        </motion.p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={2} className="d-flex justify-content-lg-end">
                        <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                        >
                            <a className={"mx-2"} href={"https://facebook.com"}>
                                <i className="bi bi-facebook"></i>
                            </a>
                            <a className={"mx-2"} href={"https://instagram.com"}>
                                <i className="bi bi-instagram"></i>
                            </a>
                            <a className={"mx-2"} href={"https://x.com"}>
                                <i className="bi bi-twitter-x"></i>
                            </a>
                        </motion.p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}