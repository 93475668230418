import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export function ProfileInfoForm({ formData, handleChange }) {

    const { t } = useTranslation();

    
    return (
        <>
            <h2>{t('complete_profile')}</h2>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile2')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="first_name" 
                            value={formData.first_name} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile2')}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile3')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="last_name" 
                            value={formData.last_name} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile3')}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile7')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="street" 
                            value={formData.street} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile7')}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile8')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="house_number" 
                            value={formData.house_number} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile8')} 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile9')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="zip_code" 
                            value={formData.zip_code} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile9')}
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile10')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="city" 
                            value={formData.city} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile10')}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile11')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="province" 
                            value={formData.province} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile11')}
                            required 
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile12')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="country" 
                            value={formData.country} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile12')} 
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile4')}</Form.Label>
                        <Form.Control 
                            type="date" 
                            name="birthdate" 
                            value={formData.birthdate} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile4')} 
                            required
                        />
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>{t('complete_profile13')}</Form.Label>
                        <Form.Control 
                            type="text" 
                            name="phone_number" 
                            value={formData.phone_number} 
                            onChange={handleChange} 
                            placeholder={t('complete_profile13')}
                            required
                        />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}