import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { motion } from 'framer-motion';

const AuctionDetailModal = ({ show, handleClose, auction }) => {
    if (!auction) return null;

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            centered
            backdrop="static"
            keyboard={false}
            dialogClassName="custom-modal"
        >
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.4 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Veiling Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <h4>Naam:</h4>
                        <p>{auction.name}</p>

                        <h4>Beschrijving:</h4>
                        <p>{auction.description}</p>

                        <h4>Startdatum:</h4>
                        <p>{new Date(auction.start_date).toLocaleString()}</p>

                        <h4>Einddatum:</h4>
                        <p>{new Date(auction.end_date).toLocaleString()}</p>

                        <h4>Status:</h4>
                        <p>{auction.status}</p>

                        <h4>Financiering:</h4>
                        <p>{auction.financing ? 'Ja' : 'Nee'}</p>
                    </motion.div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Sluit
                    </Button>
                </Modal.Footer>
            </motion.div>
        </Modal>
    );
};

export default AuctionDetailModal;