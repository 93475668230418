import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
  Alert,
  Form,
  Card,
} from "react-bootstrap";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./AgendaPage.css";
import "../appointment-buyer.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import BelgiumMap from "../components/BelgiumMap";

export function AgendaPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [slots, setSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const user = userMetadata;
  const isSeller =
    (selectedAppointment && selectedAppointment.seller === user.id) ||
    (userMetadata &&
      (userMetadata.type === "seller" || userMetadata.type === "company"));
  const token = apiAccessToken;
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = [t("agenda"), "USELL"];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  const { appointmentId } = useParams();

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/appointments/user/${user.id}/all/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        setAppointments(data);

        if (appointmentId) {
          const matchedAppointment = data.find(
            (app) => app.id.toString() === appointmentId
          );
          if (matchedAppointment) {
            setSelectedAppointment(matchedAppointment);
          }
        }
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    fetchAppointments();
  }, [user, token, appointmentId]);

  useEffect(() => {
    if (!selectedAppointment) return;
    const uniqueDays = [
      ...new Set(
        slots.map((slot) =>
          new Date(slot.date_time).toLocaleDateString("en-GB")
        )
      ),
    ];
    setAvailableDays(uniqueDays);
  }, [slots, selectedAppointment]);

  useEffect(() => {
    if (!user?.id || !token) return;
    if (selectedAppointment) {
      const fetchAndValidateSlots = async (appointmentId) => {
        const slotsURL = `https://usell.be/api/slots/?appointment_id=${appointmentId}`;
        const validateURL = `https://usell.be/api/appointments/${appointmentId}/validate-timeslots/`;

        const options = {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };

        try {
          const response = await fetch(slotsURL, options);
          if (!response.ok) throw new Error("Error fetching slots");
          const data = await response.json();

          if (isSeller) {
            const now = new Date();

            const allSlotsInPast =
              data.length > 0 &&
              data.every((slot) => new Date(slot.date_time) < now);

            if (data.length === 0 || allSlotsInPast) {
              await fetch(validateURL, options);

              const newResponse = await fetch(slotsURL, options);
              if (!newResponse.ok) throw new Error("Error fetching slots");
              const newData = await newResponse.json();
              setSlots(newData);
            } else {
              setSlots(data);
            }
          } else {
            const buyerSlots = data.filter(
              (slot) => slot.selected_slot_seller === true
            );
            setSlots(buyerSlots);
          }
        } catch (error) {
          console.error("Error fetching slots:", error);
        }
      };

      fetchAndValidateSlots(selectedAppointment.id);
    }
  }, [selectedAppointment, isSeller, user, token]);

  const handleSlotSelect = (slotId) => {
    if (isSeller) {
      setSelectedSlots((prevSelectedSlots) => {
        if (prevSelectedSlots.includes(slotId)) {
          return prevSelectedSlots.filter((id) => id !== slotId);
        }
        if (prevSelectedSlots.length < 5) {
          return [...prevSelectedSlots, slotId];
        }
        return prevSelectedSlots;
      });
    } else {
      setSelectedSlot(slotId === selectedSlot ? null : slotId);
    }
  };

  const submitSlots = async () => {
    if (!selectedAppointment || selectedSlots.length !== 5) {
      alert("You must select exactly 5 slots to submit.");
      return;
    }

    const selectedSlotDates = selectedSlots.map((slotId) => {
      const slot = slots.find((s) => s.id === slotId);
      return new Date(slot.date_time).toISOString();
    });

    const URL = `https://usell.be/api/appointments/${selectedAppointment.id}/submit_slots/`;
    const token = apiAccessToken;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        slots: selectedSlotDates,
      }),
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok)
        throw new Error("Het bevestigen van een tijdstip is mislukt.");

      navigate("/dashboard/afspraken", {
        state: { successMessage: "Tijdsloten succesvol ingediend!" },
      });
    } catch (error) {
      console.error("Het bevestigen van een tijdstip is mislukt.:", error);
    }
  };

  const confirmSlot = async () => {
    if (!selectedSlot) return;

    const URL = `https://usell.be/api/slots/confirm/${selectedSlot}/`;
    const token = apiAccessToken;
    const options = {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(
          errorData.error ||
            "Het bevestigen van een tijdstip is mislukt. Gelieve uw betaling te vervolledigen en vervolgens opnieuw te proberen"
        );
        return;
      }

      const data = await response.json();
      setSelectedAppointment({
        ...selectedAppointment,
        appointment_date: data.appointment_date,
        status: "Accepted",
      });

      navigate("/dashboard/afspraken", {
        state: { successMessage: "Tijdslot succesvol bevestigd voor de afspraak!" },
      });
    } catch (error) {
      console.error("Failed to confirm slot:", error);
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const declineSlots = async () => {
    if (!selectedAppointment) return;

    const URL = `https://usell.be/api/appointments/${selectedAppointment.id}/reset/`;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiAccessToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) throw new Error("Failed to reset appointment");
      navigate("/dashboard/afspraken", {
        state: { successMessage: "Tijdsloten succesvol geweigerd!" },
      });
    } catch (error) {
      console.error("Error resetting appointment:", error);
    }
  };

  const renderSlotsForDay = () => {
    if (!selectedDay) return null;

    const filteredSlots = slots.filter(
      (slot) =>
        new Date(slot.date_time).toLocaleDateString("en-GB") === selectedDay
    );

    return (
      <Row>
        {filteredSlots.map((slot) => (
          <Col xs={4} sm={3} md={2} className="mb-2" key={slot.id}>
            <Button
              className={`slot ${
                isSeller && selectedSlots.includes(slot.id)
                  ? "selected"
                  : selectedSlot === slot.id
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSlotSelect(slot.id)}
            >
              {new Date(slot.date_time).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </Button>
          </Col>
        ))}
      </Row>
    );
  };

  const renderSelectedSlotsLog = () => {
    if (isSeller && slots.length === 0) return null;
    if (!isSeller && !slots) return null;

    return (
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>{t("Geselecteerde tijdsloten")}</Card.Title>
          <ul>
            {isSeller
              ? selectedSlots.map((slotId) => {
                  const slot = slots.find((s) => s.id === slotId);
                  return (
                    <li key={slotId}>
                      {new Date(slot.date_time).toLocaleString()}
                    </li>
                  );
                })
              : selectedSlot && (
                  <li>
                    {new Date(
                      slots.find((s) => s.id === selectedSlot).date_time
                    ).toLocaleString()}
                  </li>
                )}
          </ul>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <UserNavigation />
      <motion.div
        className={"agenda-hero"}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className={"agenda-background"}></div>
        <Container
          className={"content content-padding-top-lg content-padding-bottom-lg"}
        >
          <motion.h1
            className={"agenda-title"}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {dynamicTitle}
          </motion.h1>
        </Container>
      </motion.div>
      <Container className={"content agenda-container"}>
        <Row>
          <DropdownButton className={"button-centraal"} title={t("agenda2")}>
            {appointments
              .filter(
                (app) =>
                  app.status !== "ACCEPTED" &&
                  app.status !== "COMPLETED" &&
                  app.status !== "Suspended"
              )
              .map((app) => (
                <Dropdown.Item
                  key={app.id}
                  onClick={() => setSelectedAppointment(app)}
                >
                  {t("agenda3")} #{app.id} -{" "}
                  {app.owner_region || t("no_region")} - {app.auction_car.name}{" "}
                  {app.auction_car.model} {app.auction_car.generation}{" "}
                  {app.auction_car.series}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </Row>

        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage("")}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}

        {selectedAppointment && (
          <Row>
            <Col md={6}>
              <BelgiumMap selectedRegion={selectedAppointment.owner_region} />
            </Col>
            <Col md={6}>
              <Form>
                <Form.Group controlId="daySelect">
                  <Form.Label>{t("Selecteer een dag")}</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedDay}
                    onChange={(e) => setSelectedDay(e.target.value)}
                  >
                    <option value="">{t("Selecteer een dag...")}</option>
                    {availableDays.map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form>
              <div className="time-slots mt-4">{renderSlotsForDay()}</div>
              <div className="text-center mt-4">
                {isSeller ? (
                  <Button
                    onClick={submitSlots}
                    disabled={selectedSlots.length !== 5}
                  >
                    {t("Bevestig slots")}
                  </Button>
                ) : (
                  <>
                    {slots.length > 0 && (
                      <Row className="mt-3">
                        <Col>
                          <Button
                            onClick={confirmSlot}
                            disabled={!selectedSlot}
                          >
                            {t("agenda7")}
                          </Button>
                          <Button onClick={declineSlots}>{t("agenda8")}</Button>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </div>
              {renderSelectedSlotsLog()}
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
}
