import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button, Card, Alert } from "react-bootstrap";
import { AuthContext } from "../../contexts/AuthContext";
import "../../appointment-buyer.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { UserNavigation } from "../../components/UserNavigation";
import { FaTimes, FaClock, FaCheckCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

export function AppointmentsPage() {
  const [appointments, setAppointments] = useState([]);
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const location = useLocation();
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage || "");
  const user = userMetadata;
  const token = apiAccessToken;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = [t("appointment13"), t("USELL")];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/appointments/user/${user.id}/all/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        console.log("Appointments:", data);
        setAppointments(data);
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    if (user) {
      fetchAppointments();
    }
  }, [user, token]);

  const getAnimationSrc = (status) => {
    switch (status.toUpperCase()) {
      case "PENDING":
        return "/images/SVG/appointment-pending.json";
      case "ACCEPTED":
        return "/images/SVG/appointment-ongoing.json";
      case "COMPLETED":
        return "/images/SVG/appointment-completed.json";
      default:
        return "/images/SVG/appointment-pending.json";
    }
  };

  const handleAppointmentClick = (appointmentId) => {
    navigate(`/dashboard/agenda/${appointmentId}`);
  };

  const handleProceedToSale = async (appointmentId, userType) => {
    try {
      const response = await fetch(
        `https://usell.be/api/appointments/${appointmentId}/proceed-to-sale/`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_type: userType }),
        }
      );

      if (!response.ok) throw new Error("Failed to proceed to sale");

      window.location.reload();
    } catch (error) {
      console.error("Error proceeding to sale:", error);
    }
  };

  const handleDeclineAppointment = async (appointmentId) => {
    try {
      const response = await fetch(
        `https://usell.be/api/appointments/${appointmentId}/decline-appointment/`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${apiAccessToken}`,
            Accept: "application/json",
          },
        }
      );

      if (!response.ok) throw new Error("Failed to decline appointment");

      window.location.reload();
    } catch (error) {
      console.error("Error declining appointment:", error);
    }
  };

  const handleMarkCompleted = async (appointmentId) => {
    try {
      const response = await fetch(
        `https://usell.be/api/appointments/${appointmentId}/mark-completed/`,
        {
          method: "POST",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        }
      );

      if (!response.ok)
        throw new Error("Failed to mark appointment as completed.");

      window.location.reload();
    } catch (error) {
      console.error("Error marking appointment as completed:", error);
    }
  };

  const shouldDisableButton = (app) => {
    if (userMetadata.type === "seller") {
      return app.seller_accepted === true;
    } else if (userMetadata.type === "buyer") {
      return app.buyer_accepted === true;
    }
    return false;
  };

  const renderButtons = (app) => {
    const sellerHasSelectedSlots =
      app.slots &&
      app.slots.filter((slot) => slot.selected_slot_seller).length >= 5;
    const isAppointmentDatePassed = new Date(app.appointment_date) < new Date();
    const isOwner = app.seller === user.id;
    const userType =
      isOwner && userMetadata.type === "buyer" ? "seller" : userMetadata.type;

    if (app.status === "ACCEPTED") {
      if (userType === "seller" || userType === "company") {
        console.log("Rendering ACCEPTED view for seller/company");
        return <p>{t("appointment1")}</p>;
      } else if (userType === "buyer") {
        console.log("Rendering ACCEPTED view for buyer");
        return (
          <>
            <Button
              variant="danger"
              className="ms-2"
              disabled={!isAppointmentDatePassed}
              onClick={() => handleDeclineAppointment(app.id)}
            >
              <FaTimes className="me-1" /> {t("appointment2")}
            </Button>
            <Button
              variant="success"
              className="ms-2"
              disabled={!isAppointmentDatePassed}
              onClick={() =>
                isAppointmentDatePassed && handleMarkCompleted(app.id)
              }
            >
              <FaCheckCircle className="me-1" /> {t("appointment3")}
            </Button>
          </>
        );
      }
    }

    if (app.status === "Pending") {
      if (userType === "seller" || userType === "company") {
        console.log("Rendering PENDING view for seller/company");
        if (sellerHasSelectedSlots) {
          return <p>{t("appointment5")}</p>;
        } else {
          return (
            <Button
              variant="primary"
              onClick={() => handleAppointmentClick(app.id)}
            >
              {t("appointment4")}
            </Button>
          );
        }
      } else if (userType === "buyer") {
        console.log("Rendering PENDING view for buyer");
        if (sellerHasSelectedSlots) {
          return (
            <Button
              variant="primary"
              onClick={() => handleAppointmentClick(app.id)}
            >
              {t("appointment6")}
            </Button>
          );
        } else {
          return <p>{t("appointment7")}</p>;
        }
      }
    }

    if (app.status === "COMPLETED") {
      if (userType === "seller" || userType === "company") {
        console.log("Rendering COMPLETED view for seller/company");
        return (
          <Button
            variant="success"
            disabled={shouldDisableButton(app)}
            onClick={() => handleProceedToSale(app.id, "seller")}
          >
            {t("appointment8")}
          </Button>
        );
      }

      if (userType === "buyer") {
        console.log("Rendering COMPLETED view for buyer");
        return (
          <Button
            variant="success"
            disabled={shouldDisableButton(app)}
            onClick={() => handleProceedToSale(app.id, "buyer")}
          >
            {t("appointment10")}
          </Button>
        );
      }
    }

    return null;
  };

  const renderAppointments = (status) => {
    return appointments
      .filter(
        (app) =>
          app.status.toLowerCase() === status.toLowerCase() &&
          (!app.auction ||
            app.auction.status !== "FINISHED" ||
            !app.stockItem ||
            app.stockItem.status !== "FINISHED")
      )

      .map((app) => (
        <Card key={app.id} className="mb-3 appointment-card">
          <Card.Body>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Player
                autoplay
                loop
                src={getAnimationSrc(app.status)}
                style={{
                  width: "48px",
                  height: "48px",
                }}
              />
              <Card.Title>
                {app.appointment_date
                  ? new Date(app.appointment_date).toLocaleString()
                  : "Nog geen datum"}
              </Card.Title>
            </div>
            <br></br>
            <Card.Text>Status: {app.status}</Card.Text>
            <Card.Text>Merk: {app.auction_car.name}</Card.Text>
            <Card.Text>Model: {app.auction_car.model}</Card.Text>
            <Card.Text>Generatie: {app.auction_car.generation}</Card.Text>
            <Card.Text>Series: {app.auction_car.series}</Card.Text>
            {renderButtons(app)}
          </Card.Body>
        </Card>
      ));
  };
  if (!userMetadata) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <>
      <UserNavigation />
      <motion.div
        className={"appointments-hero"}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className={"appointments-background"}></div>
        <Container
          className={"content content-padding-top-lg content-padding-bottom-lg"}
        >
          <motion.h1
            className={"appointments-title"}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {dynamicTitle}
          </motion.h1>
        </Container>
      </motion.div>
      <Container className={"afspraken"}>
        {successMessage && (
          <Alert
            variant="success"
            onClose={() => setSuccessMessage("")}
            dismissible
          >
            {successMessage}
          </Alert>
        )}
        <Row>
          <Col md={4}>
            <motion.div
              className="title-box pending-box"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <FaClock className="me-2" />
              <h3 className="titel-afspraken">{t("appointment14")}</h3>
            </motion.div>
            {renderAppointments("Pending")}
          </Col>
          <Col md={4}>
            <motion.div
              className="title-box accepted-box"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.4 }}
            >
              <FaCheckCircle className="me-2" />
              <h3 className="titel-afspraken">{t("appointment15")}</h3>
            </motion.div>
            {renderAppointments("Accepted")}
          </Col>
          <Col md={4}>
            <motion.div
              className="title-box completed-box"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
            >
              <FaCheckCircle className="me-2" />
              <h3 className="titel-afspraken">{t("appointment16")}</h3>
            </motion.div>
            {renderAppointments("Completed")}
          </Col>
        </Row>
      </Container>
    </>
  );
}
