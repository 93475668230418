import { Col } from "react-bootstrap";
import { StockCard } from "./StockCard";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export function StockList({ stockItems }) {
  if (!stockItems) return "Loading";


  return (
    <>
      {stockItems.map((stockItem) => (
        <Col lg={4} md={6} className="mb-4" key={stockItem.id}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Link to={`/stock/${stockItem.id}`}>
              <StockCard
                title={stockItem.name}
                buildyear={stockItem.car.build_year}
                mileage={stockItem.car.kilometers}
                fuelType={stockItem.car.fuel_type}
                image={stockItem.image_url || "/images/cars/opel.webp"}
                endDate={stockItem.end_date}
                highestBid={stockItem.highest_bid}
                stockItemId={stockItem.id}
                visitCount={stockItem.visit_count}
              />
            </Link>
          </motion.div>
        </Col>
      ))}
    </>
  );
}
