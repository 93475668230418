import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const SellerRoute = ({ children }) => {
    const { userMetadata } = useContext(AuthContext);

    if (!userMetadata) {
        return <Navigate to="/" />;
    }

    if (userMetadata.type !== 'seller' && userMetadata.type !== 'company') {
        return <Navigate to="/dashboard" />;
    }

    return children;
};

export default SellerRoute;