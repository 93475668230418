import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        unread_notifications: "Action Needed",
        mark_as_read: "Mark as read",
        close: "Close",
        join_button: "JOIN NOW",
        usell_title: "U-SELL",
        hero_subtitle: "UNIQUE AUCTION SITE FOR ALL VEHICLE TYPES.",
        become_member: "BECOME A MEMBER RIGHT NOW!",
        about_pre: "WHAT IS",
        about_text:
          "We are the first transparent auction site for selling second-hand vehicles in Belgium. Usell guarantees the best price for your car by putting the owner completely in control. Every choice related to your property is entirely in your hands. Your car = your auction = your choice! Additionally, businesses can offer their VAT cars. You will be able to receive bids from our professional partners for five days, all of whom are thoroughly screened before accessing the platform and continuously monitored by our expertise with feedback from customers. This allows us to guarantee transparency, quality, safety, and the best price for our customers. Moreover, we handle all the paperwork and ensure the car is picked up anywhere in Belgium within five days. Upon collection, both you and the Usell professional confirm the transfer on our platform, and within seconds, you receive the amount for which the deal was finalized. It's your deal!",
        mission_title: "OUR MISSION",
        mission_text:
          "We aim to make the second-hand car market a reliable marketplace, keeping your convenience in mind. We strive to transform current standards into new values that we, as a company, are proud of.",
        vision_title: "OUR VISION",
        vision_text:
          "Our platform is an innovative and fully automated program, emphasizing your user experience. Transparency and customer-friendliness are the cornerstones of our platform.",
        register_buyer_title: "BUY A VEHICLE",
        register_buyer_text:
          "Want to join our professional network? Start your application here.",
        register_seller_title: "SELL A VEHICLE",
        register_seller_text:
          "Want to sell a car? Register now and start selling.",
        sold_auctions_title: "WHAT HAVE WE HELPED SELL?",
        loading_auctions: "Loading auctions...",
        no_paid_auctions: "There are currently no paid auctions available.",
        create_account_title: "CREATE AN ACCOUNT",
        create_account_text1: "Start by creating an account on our platform.",
        create_account_text2:
          "Once done, you will be guided through the system so you can easily find and enter all necessary information about your car, then add a few pictures, and you're all set.",
        create_account_text3: "The auction can begin!",
        auctions_title: "AUCTIONS",
        auctions_text1:
          "Sell your car in 5 days! Your car will be on our auction for professionals. Accept a bid immediately or wait 24 hours for the highest offer. We will arrange the collection within 5 days, anywhere in Belgium. Confirm the sale at the pickup through our platform.",
        auctions_text2:
          "Important: Ensure an honest description to avoid administrative costs.",
        auctions_text3: "It's your deal!",
        // ABOUT US
        us_title: "What does",
        us_title2: "Usell",
        us_title3: "stand for?",
        pillars_title: "Our Pillars",
        pillars_text:
          "As an innovative partner in the second-hand car market, we make a difference with two key pillars: transparency and user-friendliness.",
        first_title: "Transparency (free market)",
        first_text:
          "The customer is king, and we take this very seriously. The trading space offered allows for truthful feedback and knowledge for the customer. Usell plays an open hand and is the only partner that provides the customer with an honest and realistic picture of the value of their car, completely free and without obligation.",
        last_title: "User-friendliness",
        last_text:
          "Sign up and experience optimal user-friendliness. With all the emerging technologies in today's world and rapid changes, we are not blind to older generations. That's why we designed our platform assuming everyone should be able to find their way. You will be guided through the process to ensure that your experience is smooth, enjoyable, and broadening. Usell highly values feedback, so do not hesitate to send us your comments or questions.",
        last_text2:
          "Throughout the entire process, you are completely in control. From the moment your car is listed online, you are free to choose which bid to accept. You literally hold time in your hands. U...sell = you sell. It’s your deal!",
        // CONTACT
        contact_title: "CONTACT US",
        contact_text1: "Do you have any questions or comments?",
        contact_text2: "Let us know, and we will respond as soon as possible.",
        contact_title2: "SEND US A MESSAGE",
        contact_text3: "Do you have a question? Check out our",
        contact_text4: "frequently asked questions",
        contact_text5: "for answers!",
        // CONTACT FORM
        naam: "NAME",
        naam_placeholder: "Your name",
        email: "EMAIL",
        email_placeholder: "Your email address",
        onderwerp: "SUBJECT",
        onderwerp_placeholder: "Subject",
        bericht: "MESSAGE",
        akkoord:
          "I AGREE WITH USELL'S PRIVACY POLICY. I UNDERSTAND THAT USELL WILL USE MY INFORMATION TO RESPOND TO MY QUESTIONS.",
        contact_button: "SEND",
        socials: "REACH US THROUGH OTHER PLATFORMS",
        // NAVBAR
        nav1: "Home",
        nav2: "About Us",
        nav3: "Contact",
        nav4: "Sign In",
        nav5: "Dashboard",
        nav6: "Log Out",
        footer1: "MENU",
        footer2: "Home",
        footer3: "About Us",
        footer4: "Contact",
        footer5: "CONTACT",
        footer6: "LEGAL",
        footer7: "Claim",
        footer8: "Terms",
        footer9: "Privacy Policy",
        footer10: "Cookie Policy",
        footer_text: "© Copyright Usell. All rights reserved.",
        //UserNavigation SELLER
        navbar_title1: "Auctions",
        navbar_1: "My Auctions",
        navbar_2: "Create Auction",
        navbar_3: "Repost Auction",
        navbar_title2: "Calendar",
        navbar_4: "Appointments",
        navbar_5: "Schedule",
        navbar_title3: "Bids",
        navbar_title4: "My Profile",
        navbar_title5: "Stock",
        //UserNavigation BUYER
        navbar_6: "Winning Auctions",
        navbar_7: "Upcoming Auctions",
        navbar_8: "Ongoing Auctions",
        navbar_9: "Won Auctions",
        navbar_10: "My Stock",
        navbar_11: "Available Stock",
        navbar_12: "Sell Stock",
        navbar_13: "My Stock bids",
        //DASHBOARD
        dashboard_title: "Dashboard",
        dashboard1: "Active Auctions",
        dashboard2: "Manage and view your active auctions.",
        dashboard3: "Create a New Auction",
        dashboard4: "Easily create a new auction via the form.",
        dashboard5: "Appointments",
        dashboard6: "Manage and view your appointments.",
        dashboard7: "My Profile",
        dashboard8: "View and edit your profile.",
        dashboard9: "Ongoing Auctions",
        dashboard10: "View your ongoing auctions.",
        dashboard11: "Won Auctions",
        dashboard12: "View your won auctions.",
        dashboard13: "Appointments",
        dashboard14: "Manage and view your appointments.",
        dashboard15: "My Profile",
        dashboard16: "View and edit your profile.",
        //WINNING-AUCTIONS
        winning_title: "WINNING AUCTIONS",
        winning_text: "You currently have the highest bid on these auctions.",
        //FOLLOWING-AUCTIONS
        following_title: "FOLLOWING AUCTIONS",
        following_text: "View the auctions you are following.",
        //LOPENDE VEILINGEN
        lopende_title: "AUCTIONS",
        lopende_title2: "USELL",
        lopende_text1: "View our auctions. Found one you like?",
        lopende_text2: "Click on it for more details.",
        stock_text1: "View the stock of our professionals. Found one you like?",
        stock_text2: "Click on it for more details.",
        stock_text3:
          "This is you're personal stockpage. Here you can see a detailed overview out of all the stock placed on usell.",
        //WON-AUCTIONS
        won_title: "WON AUCTIONS",
        won_text: "You have won these auctions as the highest bidder.",
        //APPOINTMENT
        appointment1:
          "Please wait until the buyer confirms or cancels the sale after the appointment.",
        appointment2: "Cancel",
        appointment3: "Accept",
        appointment4: "Open schedule",
        appointment5: "Please wait until the buyer has accepted a time slot.",
        appointment6: "Open schedule",
        appointment7: "Please wait until the seller has selected 5 time slots.",
        appointment8: "Proceed to sale",
        appointment9: "Please wait until the other party agrees to the sale.",
        appointment10: "Proceed to sale",
        appointment11: "Please wait until the other party agrees to the sale.",
        appointment12: "No date yet",
        appointment13: "Appointments",
        appointment14: "To be scheduled",
        appointment15: "Scheduled appointments",
        appointment16: "Completed appointments",
        //AGENDA
        agenda: "Schedule",
        agenda2: "Select an appointment",
        agenda3: "Appointment",
        agenda4: "Select time slots for your appointment",
        agenda5: "Submit time slots",
        agenda6: "Available time slots for appointment",
        agenda7: "Accept",
        agenda8: "Reject all",
        agenda9: "Confirmed appointments",
        agenda10: "Your appointment is confirmed for",
        agenda11:
          "The seller has not submitted any time slots yet. Please try again later.",
        //PROFIEL
        profile: "Remove profile picture",
        profile1: "Profile",
        profile2: "Personal Information",
        profile3: "First Name",
        profile4: "Last Name",
        profile5: "Email",
        profile6: "Date of Birth",
        profile7: "Address",
        profile8: "Street",
        profile9: "House number",
        profile10: "Postal code",
        profile11: "City",
        profile12: "Province",
        profile13: "Country",
        profile14: "Phone",
        profile15: "Edit personal info",
        profile16: "Change password",
        profile17: "Edit personal info",
        profile18: "Close",
        profile19: "Save changes",
        profile20: "Change password",
        profile21: "Close",
        profile22: "Save changes",
        profile23: "Change profile picture",
        profile24: "Cancel",
        profile25: "Save",
        profile26: "Delete Account",
        //MIJN VEILINGEN
        userveiling: "YOUR AUCTIONS",
        userveiling2: "Here is a list of your auctions.",
        userveiling3: "Active auctions",
        userveiling4: "Refresh active auctions",
        userveiling5: "History",
        userveiling6: "Refresh history",
        userveiling7: "Search auctions...",
        userveiling8: "Your active auctions",
        userveiling9: "Your auction history",
        userveiling10: "In Progress",
        userveiling11: "Refresh auctions",
        userveiling12: "PENDING",
        userveiling13: "UNPAID",
        userveiling14: "CONFIRMED",
        userveiling15: "ACCEPTED",
        userveiling16: "PAID",
        userveiling17: "These are the auctions in progress",
        //BIEDINGEN
        bid: "Bids",
        bid2: "My auctions - Highest Bids",
        bid3: "Auction name",
        bid4: "Highest bid",
        bid5: "Actions",
        bid6: "No bids yet",
        bid7: "Accept",
        bid8: "Reject",
        bid9: "Active Auctions with Highest Bids",
        bid10: "My Stock - Highest bids",
        bid11: "Stock name",
        bid12: "My Ended Stock - Highest bids",
        //RELIST AUCTION
        relist: "Blocked auctions",
        relist2: "Blocked auctions - Repost",
        relist3: "Auction name",
        relist4: "Status",
        relist5: "Actions",
        relist6: "Repost auction",
        //CREATE AUCTION PAGE
        create: "Create a new auction",
        create2: "Auction details",
        create3: "Name *",
        create3_placeholder: "Auction name",
        create4: "Starting price",
        create4_placeholder: "Starting price",
        create5: "Brand *",
        create5_placeholder: "Select a brand",
        create6: "Model *",
        create6_placeholder: "Select a model",
        create7: "Year *",
        create7_placeholder: "Year",
        create8: "Chassis number *",
        create8_placeholder: "Chassis number",
        create9: "Generation *",
        create9_placeholder: "Select a generation",
        create10: "Series *",
        create10_placeholder: "Select a series",
        create11: "Trim *",
        create11_placeholder: "Select a trim",
        create12: "Color *",
        create12_placeholder: "Color",
        create13: "Number of doors *",
        create14: "Mileage *",
        create15: "Engine *",
        create16: "Transmission",
        create16_placeholder1: "Automatic",
        create16_placeholder2: "Semi-automatic",
        create16_placeholder3: "Manual",
        create17: "Drive",
        create17_placeholder1: "Front-wheel drive",
        create17_placeholder2: "Rear-wheel drive",
        create17_placeholder3: "All-wheel drive",
        create18: "Fuel",
        create18_placeholder1: "Diesel",
        create18_placeholder2: "Petrol",
        create18_placeholder3: "Hybrid",
        create18_placeholder4: "LPG",
        create18_placeholder5: "Electric",
        create19: "Options",
        create19_placeholder: "Options (separate by commas)",
        create20: "Damage",
        create20_placeholder: "Damage (separate by commas)",
        create21: "Board document * (Max 1)",
        create22: "Description",
        create22_placeholder: "Description",
        create23: "Images (Maximum 10)",
        create24: "Financing available",
        create25: "VAT system",
        create25_placeholder: "VAT system",
        create26: "Appointment Address",
        create27: "Street",
        create28: "No.",
        create29: "Postal Code",
        create30: "City",
        create31: "Province",
        create32: "Country",
        create33: "Phone number",
        create34: "CREATE AUCTION",
        //CARCARD
        car: "SOLD IN 1 DAY",
        car2: "Ended",
        //STOCK
        stock_title: "My Stockpage",
        stock_text: "This is you're current stockgallery",
        create43: "CREATE STOCK",
        create44: "Create a new stock",
        create45: "Stockdetails",
        //ADMINNAV
        admin_nav1: "Dashboard",
        admin_nav2: "Users",
        admin_nav3: "Auctions",
        admin_nav4: "Flagged cars",
        //COMPLETEPROFILEFORM
        complete_profile: "My information",
        complete_profile1: "Finish ure registration",
        complete_profile2: "First name",
        complete_profile3: "Last name",
        complete_profile4: "Birthdate",
        complete_profile5: "VAT-Number",
        complete_profile6: "Complete ure address",
        complete_profile7: "Street",
        complete_profile8: "Housenumber",
        complete_profile9: "Zip-code",
        complete_profile10: "City",
        complete_profile11: "Province",
        complete_profile12: "Country",
        complete_profile13: "Phonenumber",
        complete_profile14: "Finish ure registration",
        complete_profile15: "Company/professional-car-seller",
        complete_profile16: "I register as a company",
        complete_profile17: "I register as a professional car merchant",
        complete_profile18: "I accept the GDPR agreement",
        complete_profile19: "I accept the Privacy agreement",
        complete_profile20: "I accept the User agreement",
        complete_profile21: "Save changes",
        complete_profile22: "Delete ure profile",
        //COOKIEBOT
        cookie: "Accept cookies",
        cookie1:
          "We use cookies to personalise content, provide socialmedia functions and to analyse our trafic",
        cookie2: "Read more",
        cookie3: "Accept",
        cookie4: "Decline",
        onboarding: "Stripe registration was successful!",
        onboarding1:
          "Thank you for completing your registration. You can now receive payments or place bids without any issues.",
        onboarding2:
          "Go to your bids page or the auction page and try again to accept/place a bid.",
        onboarding3: "Onboarding rejected",
        onboarding4:
          "Unfortunately, your onboarding process was not successfully completed. Please try to register again or contact support.",
        detail1: "Current highest bid:",
        detail2: "No bids available",
        detail3: "GENERAL",
        detail4: "Drive",
        detail5: "FINANCING",
        detail6: "VAT SYSTEM",
        detail7: "OPTIONS",
        detail8: "No options available",
        detail9: "DAMAGES",
        detail10: "No damages available",
        detail11: "DOCUMENTS",
        detail12: "BIDS",
        detail13: "Winning Bid:",
        detail14: "Make bid",
        detail15: "Check all bids",
        detail16: "Place you're bid",
        detail17: "Amount",
        detail18: "Application fee:",
        detail19: "VAT Usell:",
        detail20: "VAT Car:",
        detail21: "Total amount:",
        detail22: "Cancel",
        detail23: "Place bid",
        detail24: "All bids",
        detail25: "Close",
        detail26: "Placed bid on:",
        detail27: "Extra information",
        detail28: "Close",
        "veiling-gedaan": "Auction ended",
        "stock-gedaan":"Stock ended",
        "gelijkvormigheidsattest":"Certificate of Conformity",
        "inschrijvingsbewijs":"Registration Certificate",
        "keuringsattest":"Inspection Certificate",
        confirmRelist: "Relist auction",
        confirmRelistMessage: "Would you like to relist the selected auction?",
        confirm: "Yes",
        cancel: "No",
        navbar_14: "Archived Stock",
        admin_nav5: "Stock",
        // FAQ Section
        faq_usell: "What is Usell?",
        faq_usell_answer:
          "Usell is an online auction platform where you can sell your second-hand car. We provide a fast and easy way to sell your car for a fair price.",

        faq_veiling: "How does the car auction on Usell work?",
        faq_veiling_answer:
          "To sell your car, sign up and enter the necessary information about your vehicle. Your car will then be placed in an online auction where interested buyers can bid.",

        faq_kosten: "What are the costs for using Usell?",
        faq_kosten_answer:
          "There are no costs for selling a vehicle on Usell. Only professionals who buy vehicles will be charged fees.",

        faq_niet_verkocht: "What happens if my car is not sold on Usell?",
        faq_niet_verkocht_answer:
          "If your car is not sold via Usell, you can choose to restart the auction or remove your car from the platform.",

        faq_waarde_auto:
          "How can I determine the value of my car before selling it on Usell?",
        faq_waarde_auto_answer:
          "When listing your vehicle online, you will be asked for a desired selling price. The highest amount you will get for your car will be a market-conform price when the auction is live.",

        faq_klantenservice:
          "Can I contact customer service if I have questions or problems?",
        faq_klantenservice_answer:
          "Yes, if you have questions or issues, you can contact our customer service via email or phone. Our team is ready to assist you.",

        faq_welke_wagens: "Can any car be sold on Usell.be?",
        faq_welke_wagens_answer:
          "Yes, any car that is legally owned by the seller can be listed on Usell.be.",

        faq_schade:
          "What happens if I don't disclose all damage information, leading to a dispute?",
        faq_schade_answer:
          "If the professional withdraws from the purchase, they must provide proof. If you have withheld information that negatively affects the car�s value, the sale may be canceled.",

        faq_meerdere_wagens: "Can I list multiple cars on Usell?",
        faq_meerdere_wagens_answer:
          "Yes, as long as you are the rightful owner, you can list multiple cars on Usell.be.",

        faq_bedrijfswagens: "Can I offer company cars for sale?",
        faq_bedrijfswagens_answer:
          "Usell supports VAT-registered vehicles. Provide your VAT number upon registration and use your company name as identification.",

        faq_bod_weigeren: "Can I refuse a bid?",
        faq_bod_weigeren_answer:
          "Yes, at Usell, you are in full control. You decide whether or not to accept a bid.",

        faq_verkoop_verzekerd: "Is my sale insured?",
        faq_verkoop_verzekerd_answer:
          "Once you accept a bid, the final check is done by the professional. If the car matches your description, the sale will proceed with mutual approval.",

        faq_auto_keuren: "Do I need a vehicle inspection?",
        faq_auto_keuren_answer:
          "No, a pre-sale inspection is only required when selling to a private individual. Since only professionals buy cars on Usell, you only need to show the latest inspection certificate.",

        faq_herroepingsrecht: "Is the right of withdrawal applicable?",
        faq_herroepingsrecht_answer:
          "No, since you are selling to a professional, they must inspect the car on-site. Once they agree to the sale, they cannot invoke a right of withdrawal.",

        faq_aanmelden_probleem: "I can't log in?",
        faq_aanmelden_probleem_answer:
          "If you cannot log in, try again and check your email for information. If you still experience issues, email info@usell.be.",

        faq_betalingen_veilig:
          "Are payments secure, and how are they processed?",
        faq_betalingen_veilig_answer:
          "Our partner Stripe ensures secure payments. Once the sale is confirmed, Usell and Stripe process the payment quickly and securely.",

        faq_transactie_niet_direct:
          "What if the transaction is not immediately completed?",
        faq_transactie_niet_direct_answer:
          "Once you confirm the sale via your private dashboard, the professional must do the same. Contact us if there are any issues with payment processing.",

        faq_prijsonderhandelingen:
          "Are there price negotiations after I accept a bid?",
        faq_prijsonderhandelingen_answer:
          "No. When the car is inspected, both you and the professional confirm the transaction on the platform, eliminating further negotiations.",

        faq_privacy: "Are my personal details safe with Usell?",
        faq_privacy_answer:
          "Usell is committed to protecting your data according to legal regulations and with proper security measures.",

        faq_thuisadres:
          "Do I have to provide my home address as the pickup location?",
        faq_thuisadres_answer:
          "No, this is not required. You can enter a different pickup address for your comfort and security.",

        faq_professionals_betrouwbaar: "Are the professionals trustworthy?",
        faq_professionals_betrouwbaar_answer:
          "Usell carefully screens all professionals and evaluates them based on customer feedback to maintain trust.",
        trader_disclaimer:
          "If the seller accepts your bid, you have won the auction and purchased an option to buy under the agreed auction conditions. The trader is solely responsible for visible and hidden defects. If the trader decides to take the vehicle after the appointment, this is considered acceptance of the purchase in its current condition.",
        trader_disclaimer_watchout:
          "Be careful:",
      },
    },
    nl: {
      translation: {
        unread_notifications: "Actie nodig",
        mark_as_read: "Markeer als gelezen",
        close: "sluiten",
        join_button: "WORD NU LID",
        usell_title: "U-SELL",
        hero_subtitle: "UNIEKE VEILINGSITE VOOR ELK TYPE VOERTUIG.",
        become_member: "WORD NU LID",
        about_pre: "WAT IS",
        about_text:
          "We zijn de eerste transparante veilingsite voor de verkoop van tweedehandsvoertuigen in België. Usell garandeert de beste prijs voor jouw wagen, dit door de eigenaar volledig centraal te zetten. Zo zal elke keuze die gemaakt moet worden met betrekking tot jouw eigendom, steeds volledig in jouw handen liggen. jouw wagen = jouw veiling = jouw keuze! Eveneens als bedrijf kan je jouw btw- wagens aanbieden.Je zult gedurende 5 dagen biedingen kunnen ontvangen van onze professionele partners, die uitvoerig gescreend worden alvorens toegang te verkrijgen tot het platform en nadien continu opgevolgd worden door onze expertise en dit met feedback van de klant.Hierdoor kunnen wij transparantie, kwaliteit, veiligheid en de beste prijs garanderen aan onze klanten.Daarnaast nemen wij alle administratie voor onze rekening en zorgen we ervoor dat de wagen om het even waar in belgie wordt opgehaald en dit binnen de 5 dagen. Bij ophaling van de wagen bevestigen zowel jijzelf als de usell professional de overdracht op ons platform en binnen enkele seconden beschik je over het bedrag waarvoor de deal werd afgesloten. It's your deal!",
        mission_title: "ONZE MISSIE",
        mission_text:
          "Wij willen van de tweedehandse automarkt een betrouwbare handelsplaats maken, met jouw gebruiksgemak in het achterhoofd. We willen de huidige normen ombouwen naar nieuwe waarden waar wij als bedrijf trots op zijn.",
        vision_title: "ONZE VISIE",
        vision_text:
          "Ons platform is een innovatief en volledig geautomatiseerd programma, dat de nadruk legt op jouw gebruikservaring. Transparantie en klantvriendelijkheid zijn de pijlers van ons platform.",
        register_buyer_title: "KOOP EEN VOERTUIG",
        register_buyer_text:
          "Wil je deel uitmaken van ons professioneel netwerk? Start hier je aanvraag.",
        register_seller_title: "VERKOOP EEN VOERTUIG",
        register_seller_text:
          "Wil je een wagen verkopen? Registreer je en ga aan de slag.",
        sold_auctions_title: "WAT HEBBEN WE AL HELPEN VERKOPEN?",
        loading_auctions: "Veilingen laden...",
        no_paid_auctions:
          "Er zijn momenteel geen betaalde veilingen beschikbaar.",
        create_account_title: "MAAK EEN ACCOUNT",
        create_account_text1: "Begin met een account te maken op ons platform.",
        create_account_text2:
          "Hierna wordt je begeleid door de werking van het systeem zodat je zonder problemen alle nodige gegevens van jouw wagen kan terugvinden en ingeven, daarna nog een paar foto's toevoegen en klaar is kees,",
        create_account_text3: "de veiling kan beginnen!",
        auctions_title: "VEILINGEN",
        auctions_text1:
          "Verkoop je auto in 5 dagen! Je auto staat op onze veiling voor professionals. Accepteer direct een bod of wacht 24 uur voor het hoogste bod. We regelen de ophaling binnen 5 dagen, overal in België. Bevestig de verkoop bij de afhaling via ons platform.",
        auctions_text2:
          "Belangrijk: Zorg voor een eerlijke beschrijving om administratiekosten te voorkomen.",
        auctions_text3: "It's your deal!",
        // OVER ONS
        us_title: "Waar staat",
        us_title2: "Usell",
        us_title3: "voor?",
        pillars_title: "Onze pijlers",
        pillars_text:
          "Als vernieuwende partner op de tweedehandse automarkt, maken we met twee pijlers het verschil: transparantie en gebruiksvriendelijkheid.",
        first_title: "Transparantie (vrije markt)",
        first_text:
          "Klant is koning en dit nemen wij zeer letterlijk. De aangeboden handelsruimte opent de deur naar waarheidsgetrouwe feedback en kennis voor de klant. Usell speelt open kaart en is de enige partner die de klant een eerlijk en realistisch beeld geeft van de waarde van zijn wagen en dit volledig gratis en vrijblijvend.",
        last_title: "Gebruiksvriendelijkheid",
        last_text:
          "Meld je aan en ervaar optimaal gebruiksgemak. Met alle opkomende technologie in de hedendaagse wereld en snelle aanpassingen, zijn we niet blind voor de oudere generaties. Daarom hebben we ons platform opgesteld vanuit de veronderstelling dat iedereen hier zijn weg in moet kunnen vinden. Je wordt doorheen het proces begeleid met als doelstelling om de ervaring vlot, aangenaam en verruimend te laten verlopen. Usell stelt feedback zeer op prijs, twijfel dus niet om opmerkingen of vragen aan ons te richten.",
        last_text2:
          "Doorheen het hele proces heb jij de touwtjes volledig in handen. Vanaf het moment dat jouw wagen online staat, sta je vrij om te kiezen met welke bieding je akkoord gaat. Je hebt de tijd letterlijk in de hand. U…..sell = u verkoopt. It’s your deal!",
        // CONTACT
        contact_title: "CONTACTEER ONS",
        contact_text1: "Heb je nog vragen of opmerkingen?",
        contact_text2: "Laat het ons weten en we antwoorden zo snel mogelijk.",
        contact_title2: "STUUR ONS EEN BERICHTJE",
        contact_text3: "Heb je een vraag? Bekijk eerst onze",
        contact_text4: "veelgestelde vragen",
        contact_text5: "voor antwoorden!",
        // CONTACT FORM
        naam: "NAAM",
        naam_placeholder: "Uw naam",
        email: "E-MAILADRES",
        email_placeholder: "E-mailadres",
        onderwerp: "ONDERWERP",
        onderwerp_placeholder: "Onderwerp",
        bericht: "BERICHT",
        akkoord:
          "IK GA AKKOORD MET DE PRIVACY-POLICY VAN USELL. IK BEGRIJP DAT USELL MIJN GEGEVENS GEBRUIKT OM MIJN VRAGEN TE BEANTWOORDEN.",
        contact_button: "VERSTUUR",
        socials: "BEREIK ONS VIA ANDERE PLATFORMEN",
        //NAVBAR
        nav1: "Home",
        nav2: "Over ons",
        nav3: "Contact",
        nav4: "Aanmelden",
        nav5: "Dashboard",
        nav6: "Afmelden",
        //FOOTER
        footer1: "MENU",
        footer2: "Home",
        footer3: "Over ons",
        footer4: "Contact",
        footer5: "CONTACT",
        footer6: "WETTELIJK",
        footer7: "Rechtsvordering",
        footer8: "Voorwaarden",
        footer9: "Privacy beleid",
        footer10: "Cookie beleid",
        footer_text:
          "© Copyright Usell. Alle rechten voorbehouden. Ontwikkeld door Developmentspot door Spot Group.",
        //UserNavigation SELLER
        navbar_title1: "Veilingen",
        navbar_1: "Mijn veilingen",
        navbar_2: "Maak veiling",
        navbar_3: "Plaats veiling opnieuw",
        navbar_title2: "Kalender",
        navbar_4: "Afspraken",
        navbar_5: "Agenda",
        navbar_title3: "Biedingen",
        navbar_title4: "Mijn profiel",
        navbar_title5: "Stock",
        //UserNavigation BUYER
        navbar_6: "Winnende veilingen",
        navbar_7: "Volgende veilingen",
        navbar_8: "Lopende veilingen",
        navbar_9: "Gewonnen veilingen",
        navbar_10: "Mijn Stock",
        navbar_11: "Beschikbare Stock",
        navbar_12: "Verkoop Stock",
        navbar_13: "Mijn stock biedingen",
        navbar_14: "Mijn archief",
        //DASHBOARD
        dashboard_title: "Dashboard",
        dashboard1: "Actieve veilingen",
        dashboard2: "Beheer en bekijk je actieve veilingen.",
        dashboard3: "Maak een nieuwe veiling",
        dashboard4: "Maak eenvoudig een nieuwe veiling aan via het formulier",
        dashboard5: "Afspraken",
        dashboard6: "Beheer en bekijk je afspraken.",
        dashboard7: "Mijn Profiel",
        dashboard8: "Bekijk en bewerk je profiel.",
        dashboard9: "Lopende veilingen",
        dashboard10: "Bekijk je lopende veilingen.",
        dashboard11: "Gewonnen veilingen",
        dashboard12: "Bekijk je gewonnen veilingen.",
        dashboard13: "Afspraken",
        dashboard14: "Beheer en bekijk je afspraken.",
        dashboard15: "Mijn Profiel",
        dashboard16: "Bekijk en bewerk je profiel.",
        //WINNING-AUCTIONS
        winning_title: "WINNENDE VEILINGEN",
        winning_text: "Bij deze veilingen heeft u momenteel het hoogste bod.",
        //FOLLOWING-AUCTIONS
        following_title: "VOLGENDE VEILINGEN",
        following_text: "Bekijk de veilingen die je volgt.",
        //LOPENDE VEILINGEN
        lopende_title: "VEILINGEN",
        lopende_title2: "USELL",
        lopende_text1:
          "Bekijk onze veilingen, heb je eentje gevonden die je leuk vindt?",
        lopende_text2: "Klik erop en bekijk meer details.",
        stock_text1:
          "Bekijk de stockwagens van onze professionals, heb je eentje gevonden die je leuk vindt?",
        stock_text2: "Klik erop en bekijk meer details.",
        stock_text3:
          "Dit is jouw persoonlijke stockpagina. Hier kun je een gedetailleerd overzicht zien van alle stock die op Usell zijn geplaatst.",
        //WON-AUCTIONS
        won_title: "GEWONNEN VEILINGEN",
        won_text: "Bij deze veilingen heeft u gewonnen als hoogste bieder",
        //APPOINTMENT
        appointment1:
          "Gelieve te wachten tot de koper de verkoop bevestigt of annuleert na de afspraak.",
        appointment2: "Annuleren",
        appointment3: "Accepteren",
        appointment4: "Open agenda",
        appointment5:
          "Gelieve te wachten tot de koper een tijdslot heeft geaccepteerd.",
        appointment6: "Open agenda",
        appointment7:
          "Gelieve te wachten tot de verkoper 5 tijdslots heeft geselecteerd.",
        appointment8: "Doorgaan tot verkoop",
        appointment9:
          "Gelieve te wachten tot de andere partij akkoord gaat met verkoop.",
        appointment10: "Doorgaan tot verkoop",
        appointment11:
          "Gelieve te wachten tot de andere partij akkoord gaat met verkoop.",
        appointment12: "Nog geen datum",
        appointment13: "Afspraken",
        appointment14: "Nog te plannen",
        appointment15: "Geplande afspraken",
        appointment16: "Voltooide afspraken",
        //AGENDA
        agenda: "Agenda",
        agenda2: "Selecteer een afspraak",
        agenda3: "Afspraak",
        agenda4: "Selecteer tijdsloten voor je afspraak",
        agenda5: "dien tijdsloten in",
        agenda6: "Beschikbare tijdslots voor afspraak",
        agenda7: "Accepteer",
        agenda8: "Weiger alle",
        agenda9: "Bevestigde afspraken",
        agenda10: "Uw afspraak is bevestigd voor",
        agenda11:
          "De verkoper heeft nog geen tijdslots ingediend. Gelieve later opnieuw te proberen.",
        //PROFIEL
        profile: "Verwijder profielfoto",
        profile1: "Profiel",
        profile2: "Persoonlijke gegevens",
        profile3: "Voornaam",
        profile4: "Achternaam",
        profile5: "Email",
        profile6: "Geboortedatum",
        profile7: "Adres",
        profile8: "Straat",
        profile9: "Huisnummer",
        profile10: "Postcode",
        profile11: "Stad",
        profile12: "Provincie",
        profile13: "Land",
        profile14: "Tel",
        profile15: "Wijzig persoonlijke info",
        profile16: "Wijzig wachtwoord",
        profile17: "Wijzig persoonlijke info",
        profile18: "Sluiten",
        profile19: "Wijzigingen opslaan",
        profile20: "Wijzig wachtwoord",
        profile21: "Sluiten",
        profile22: "Wijzigingen opslaan",
        profile23: "Pas profielfoto aan",
        profile24: "Annuleren",
        profile25: "Opslaan",
        profile26: "Verwijder account",
        //MIJN VEILINGEN
        userveiling: "UW VEILINGEN",
        userveiling2: "Hier vindt u een lijst van uw veilingen.",
        userveiling3: "Actieve veilingen",
        userveiling4: "Ververs actieve veilingen",
        userveiling5: "Geschiedenis",
        userveiling6: "Ververs geschiedenis",
        userveiling7: "Zoek veilingen...",
        userveiling8: "Uw actieve veilingen",
        userveiling9: "Uw veilingsgeschiedenis",
        userveiling10: "In Afhandeling",
        userveiling11: "Ververs veilingen",
        userveiling12: "PENDING",
        userveiling13: "UNPAID",
        userveiling14: "CONFIRMED",
        userveiling15: "ACCEPTED",
        userveiling16: "PAID",
        userveiling17: "Dit zijn uw veilingen in afhandeling",
        //BIEDINGEN
        bid: "Biedingen",
        bid2: "Mijn veilingen - Hoogste Biedingen",
        bid3: "Veiling naam",
        bid4: "Hoogste bod",
        bid5: "Handelingen",
        bid6: "Nog geen biedingen",
        bid7: "Accepteer",
        bid8: "Weiger",
        bid9: "Actieve Veilingen met Hoogste Biedingen",
        bid10: "Mijn stock - Hoogste Biedingen",
        bid11: "Stock naam",
        bid12: "Mijn beeindigde stock - Hoogste biedingen",
        //RELIST AUCTION
        relist: "Geblokkeerde veilingen",
        relist2: "Geblokkeerde veilingen - Herplaats",
        relist3: "Veiling naam",
        relist4: "Status",
        relist5: "Handelingen",
        relist6: "Veiling opnieuw plaatsen",

        //CREATE AUCTION PAGE
        create: "Maak een nieuwe veiling",
        create2: "Veilingdetails",
        create3: "Naam *",
        create3_placeholder: "Naam van de veiling",
        create4: "Startprijs",
        create4_placeholder: "Startprijs",
        create5: "Merk *",
        create5_placeholder: "Selecteer een merk",
        create6: "Model *",
        create6_placeholder: "Selecteer een model",
        create7: "Bouwjaar *",
        create7_placeholder: "Bouwjaar",
        create8: "Chassisnummer *",
        create8_placeholder: "Chassisnummer",
        create9: "Generatie *",
        create9_placeholder: "Selecteer een generatie",
        create10: "Serie *",
        create10_placeholder: "Selecteer een serie",
        create11: "Uitvoering *",
        create11_placeholder: "Selecteer een uitvoering",
        create12: "Kleur *",
        create12_placeholder: "Kleur",
        create13: "Aantal deuren *",
        create14: "Kilometerstand *",
        create15: "Motor *",
        create16: "Transmissie",
        create16_placeholder1: "Automaat",
        create16_placeholder2: "Halfautomaat",
        create16_placeholder3: "Manueel",
        create17: "Aandrijving",
        create17_placeholder1: "Voorwielaandrijving",
        create17_placeholder2: "Achterwielaandrijving",
        create17_placeholder3: "Vierwielaandrijving",
        create18: "Brandstof",
        create18_placeholder1: "Diesel",
        create18_placeholder2: "Benzine",
        create18_placeholder3: "Hybride",
        create18_placeholder4: "LPG",
        create18_placeholder5: "Elektrisch",
        create19: "Opties",
        create19_placeholder: "Opties (scheid ze met een komma)",
        create20: "Schade",
        create20_placeholder: "Schade (scheid ze met een komma)",
        create21: "Gelijkvormigheidsattest * (Max 1)",
        create22: "Beschrijving",
        create22_placeholder: "Beschrijving",
        create23: "Afbeeldingen (Maximaal 10)",
        create24: "Financiering lopend",
        create25: "BTW - Stelsel",
        create25_placeholder: "BTW - Stelsel",
        create26: "Afspraakadres",
        create27: "Straat",
        create28: "Nr.",
        create29: "Postcode",
        create30: "Stad",
        create31: "Provincie",
        create32: "Land",
        create33: "Telefoonnummer",
        create34: "MAAK VEILING",
        create35: "Inschrijvingsbewijs * (Max 1)",
        create36: "Keuringsattest * (Max 1)",
        create37: "Wagen onder financiering",
        //CARCARD
        car: "VERKOCHT IN 1 DAG",
        car2: "Afgelopen",
        //STOCK
        stock_title: "Mijn Stockpagina",
        stock_text:
          "dit is een overzicht van jouw persoonlijke stockcatalogus, zo kan je makkelijk en overzichtelijk je stock beheren",
        create43: "MAAK STOCK",
        create44: "Maak een nieuwe stock aan",
        create45: "Stockdetails",
        //ADMINNAV
        admin_nav1: "Dashboard",
        admin_nav2: "Gebruikers",
        admin_nav3: "Veilingen",
        admin_nav4: "Afgekeurde wagens",
        //COMPLETEPROFILEFORM
        complete_profile: "Mijn gegevens",
        complete_profile1: "Voltooi je registratie",
        complete_profile2: "Voornaam",
        complete_profile3: "Achternaam",
        complete_profile4: "Geboortedatum",
        complete_profile5: "Btw-nummer",
        complete_profile6: "Vul je volledige adres in",
        complete_profile7: "Straat",
        complete_profile8: "Huisnummer",
        complete_profile9: "Postcode",
        complete_profile10: "Stad",
        complete_profile11: "Provincie",
        complete_profile12: "Land",
        complete_profile13: "Telefoonnummer",
        complete_profile14: "Voltooi je registratie",
        complete_profile15: "Onderneming/Professionele autohandelaar",
        complete_profile16: "Ik registreer mij als bedrijf",
        complete_profile17: "Ik registreer mij als autohandelaar",
        complete_profile18: "Ik ga akkoord met de GDPR-voorwaarden",
        complete_profile19: "Ik ga akkoord met het Privacybeleid",
        complete_profile20: "Ik ga akkoord met de Gebruiksvoorwaarden",
        complete_profile21: "Wijzigingen opslaan",
        complete_profile22: "Verwijder uw profiel",
        cookie: "Accepteer cookies",
        cookie1:
          "We gebruiken cookies om content te personaliseren, sociale mediafuncties aan te bieden en ons verkeer te analyseren",
        cookie2: "Lees meer",
        cookie3: "Accepteren",
        cookie4: "Weigeren",
        //ONBOARDING
        onboarding: "Registratie bij Stripe is gelukt!",
        onboarding1:
          "Dank u om uw registratie te vervolledigen, U kan nu zonder problemen betalingen ontvangen of biedingen plaatsen.",
        onboarding2:
          "Ga naar jouw biedingen pagina of de veiling pagina en probeer nu opnieuw een bod te accepteren/te plaatsen.",
        onboarding3: "Onboarding geweigerd",
        onboarding4:
          "Spijtig, je onboarding process was niet succesvol voltooid. gelieve opnieuw proberen te registreren of neem contact op met support.",
        detail1: "Huidig hoogste bod:",
        detail2: "Geen biedingen beschikbaar",
        detail3: "ALGEMEEN",
        detail4: "AANDRIJVING",
        detail5: "FINANCIERING",
        detail6: "BTW-STELSEL",
        detail7: "OPTIES",
        detail8: "Geen opties beschikbaar",
        detail9: "SCHADE",
        detail10: "Geen schade beschikbaar",
        detail11: "DOCUMENTEN",
        detail12: "BIEDINGEN",
        detail13: "Winnend bod:",
        detail14: "Doe een bod",
        detail15: "Bekijk alle biedingen",
        detail16: "Plaats uw bod",
        detail17: "Bedrag",
        detail18: "Toepassingskosten:",
        detail19: "BTW Usell:",
        detail20: "BTW Auto:",
        detail21: "Totaalbedrag:",
        detail22: "Annuleren",
        detail23: "Bod plaatsen",
        detail24: "Alle biedingen",
        detail25: "Sluiten",
        detail26: "Bod geplaatst op:",
        detail27: "Extra informatie",
        detail28: "Sluiten",
        "veiling-gedaan": "Veiling beëindigd",
        "stock-gedaan":"Stock beëindigd",
        "gelijkvormigheidsattest":"Gelijkvormigheidsattest",
        "inschrijvingsbewijs":"Inschrijvingsbewijs",
        "keuringsattest":"Keuringsattest",
        confirmRelist: "Herplaats veiling",
        confirmRelistMessage: "Wil je graag deze veiling opnieuw actief plaatsen?",
        confirm: "Ja",
        cancel: "Nee",
        admin_nav5: "Stock",
        //FAQ NL
        footerFAQ: "Veel gestelde vragen",
        faq_usell: "Wat is Usell?",
        faq_usell_answer:
          "Usell is een online veilingsplatform waar u uw tweedehandswagen kunt verkopen. Wij bieden een snelle en eenvoudige manier om uw auto te verkopen voor een eerlijke prijs.",

        faq_veiling: "Hoe werkt de autoveiling op Usell?",
        faq_veiling_answer:
          "Om uw auto te verkopen, meldt u zich aan en vult u de nodige informatie over uw voertuig in. Vervolgens wordt uw auto in een online veiling geplaatst waar ge�nteresseerde kopers kunnen bieden.",

        faq_kosten: "Wat zijn de kosten voor het gebruik van Usell?",
        faq_kosten_answer:
          "Er zijn geen kosten verbonden aan het verkopen van een voertuig op Usell. Alleen professionele kopers betalen een vergoeding.",

        faq_niet_verkocht:
          "Wat gebeurt er als mijn auto niet wordt verkocht via Usell?",
        faq_niet_verkocht_answer:
          "Als uw auto niet wordt verkocht via Usell, kunt u ervoor kiezen om de veiling opnieuw te starten of uw auto van het platform te verwijderen.",

        faq_waarde_auto:
          "Hoe kan ik de waarde van mijn auto bepalen voordat ik deze via Usell verkoop?",
        faq_waarde_auto_answer:
          "Bij het online plaatsen van uw voertuig wordt u gevraagd een gewenst verkoopbedrag op te geven. Het hoogste bedrag dat u krijgt, zal een marktconform bedrag zijn op het moment dat de veiling loopt.",

        faq_klantenservice:
          "Kan ik contact opnemen met de klantenservice als ik vragen of problemen heb?",
        faq_klantenservice_answer:
          "Ja, als u vragen of problemen heeft, kunt u contact opnemen met onze klantenservice via e-mail of telefoon. Ons team staat klaar om u te helpen.",

        faq_welke_wagens: "Kan elke wagen verkocht worden op Usell.be?",
        faq_welke_wagens_answer:
          "Ja, elke wagen die eigendom is van de verkoper en correct geregistreerd staat, kan op Usell.be worden aangeboden.",

        faq_schade:
          "Wat gebeurt er als ik niet alle schade-informatie deel en dit tot discussie leidt?",
        faq_schade_answer:
          "Als de professional zich terugtrekt van de aankoop, moet hij bewijs leveren. Als blijkt dat u informatie hebt achtergehouden die de waarde van het voertuig negatief be�nvloedt, kan de verkoop worden geannuleerd.",

        faq_meerdere_wagens: "Kan ik meerdere wagens aanbieden op Usell?",
        faq_meerdere_wagens_answer:
          "Ja, zolang u de rechtmatige eigenaar bent, kunt u meerdere wagens aanbieden op Usell.be.",

        faq_bedrijfswagens: "Kan ik bedrijfswagens verkopen?",
        faq_bedrijfswagens_answer:
          "Usell ondersteunt de verkoop van btw-wagens. Geef uw btw-nummer op bij de registratie en gebruik uw bedrijfsnaam als identificatie.",

        faq_bod_weigeren: "Kan ik een bod weigeren?",
        faq_bod_weigeren_answer:
          "Ja, bij Usell heeft u de volledige controle. U beslist zelf of u een bod accepteert of niet.",

        faq_verkoop_verzekerd: "Is mijn verkoop verzekerd?",
        faq_verkoop_verzekerd_answer:
          "Zodra u een bod accepteert, voert de professional een controle uit. Als de wagen overeenkomt met uw beschrijving, wordt de verkoop afgerond met goedkeuring van beide partijen.",

        faq_auto_keuren: "Moet ik mijn auto laten keuren?",
        faq_auto_keuren_answer:
          "Nee, een keuring voor verkoop is enkel verplicht als u aan een particulier verkoopt. Aangezien enkel professionals via Usell kopen, hoeft u alleen het laatste keuringsbewijs te tonen.",

        faq_herroepingsrecht: "Is het herroepingsrecht van toepassing?",
        faq_herroepingsrecht_answer:
          "Nee, omdat u aan een professional verkoopt, moet deze de auto ter plaatse inspecteren. Zodra de verkoop is bevestigd, kan hij zich niet beroepen op het herroepingsrecht.",

        faq_aanmelden_probleem: "Ik kan mij niet aanmelden?",
        faq_aanmelden_probleem_answer:
          "Als u zich niet kunt aanmelden, probeer het opnieuw en controleer uw e-mail voor informatie. Indien u nog steeds problemen ondervindt, neem dan contact op via info@usell.be.",

        faq_betalingen_veilig:
          "Zijn betalingen veilig en hoe worden ze uitgevoerd?",
        faq_betalingen_veilig_answer:
          "Onze partner Stripe zorgt voor veilige betalingen. Zodra de verkoop is bevestigd, verwerkt Usell samen met Stripe de betaling snel en correct.",

        faq_transactie_niet_direct:
          "Wat als de transactie niet meteen wordt uitgevoerd?",
        faq_transactie_niet_direct_answer:
          "Zodra u de verkoop bevestigt via uw dashboard, moet de professional dit ook doen. Bij problemen kunt u contact met ons opnemen voor hulp.",

        faq_prijsonderhandelingen:
          "Zijn er nog prijsonderhandelingen nadat ik het bod heb geaccepteerd?",
        faq_prijsonderhandelingen_answer:
          "Nee. Bij de controle bevestigen zowel u als de professional de transactie via het platform, waardoor verdere onderhandelingen over de prijs niet mogelijk zijn.",

        faq_privacy: "Zijn mijn persoonlijke gegevens veilig bij Usell?",
        faq_privacy_answer:
          "Usell doet er alles aan om uw gegevens te beschermen volgens de wetgeving en met de nodige beveiligingsmaatregelen.",

        faq_thuisadres: "Moet ik mijn thuisadres opgeven als ophaaladres?",
        faq_thuisadres_answer:
          "Nee, dit is niet verplicht. U kunt een ander adres opgeven als ophaaladres voor uw gemak en veiligheid.",

        faq_professionals_betrouwbaar: "Zijn de professionals betrouwbaar?",
        faq_professionals_betrouwbaar_answer:
          "Usell screent alle professionals zorgvuldig en beoordeelt hen op basis van klantfeedback om betrouwbaarheid te garanderen.",
        trader_disclaimer:
          "Indien de verkoper akkoord gaat met je bod, dan heb je de veiling gewonnen en heb je een optie tot aankoop gekocht aan de afgesproken voorwaarden van de veiling. De handelaar is zelf aansprakelijk voor zichtbare en onzichtbare gebreken. Als hij besluit om de wagen mee te nemen na de afspraak, geldt dit als akkoord van de aankoop in de huidige staat.",
        trader_disclaimer_watchout:
          "Let op:",
      },
    },
    fr: {
      translation: {
        unread_notifications: "Besoin action",
        mark_as_read: "Marquee",
        close: "arreter",
        join_button: "DEVENEZ MEMBRE",
        usell_title: "U-SELL",
        hero_subtitle: "SITE D'ENCHÈRES UNIQUE POUR TOUS TYPES DE VÉHICULES.",
        become_member: "DEVENEZ MEMBRE MAINTENANT!",
        about_pre: "QU'EST-CE QUE",
        about_text:
          "Nous sommes le premier site d'enchères transparent pour la vente de véhicules d'occasion en Belgique. Usell garantit le meilleur prix pour votre voiture en mettant le propriétaire entièrement aux commandes. Toutes les décisions concernant votre véhicule sont entre vos mains. Votre voiture = votre enchère = votre choix! Même en tant qu'entreprise, vous pouvez proposer vos voitures TVA. Vous pourrez recevoir des offres de nos partenaires professionnels pendant 5 jours, qui sont minutieusement contrôlés avant d'accéder à la plateforme et continuellement suivis grâce aux retours des clients. Cela garantit la transparence, la qualité, la sécurité et le meilleur prix à nos clients. De plus, nous nous occupons de toute l'administration et nous nous assurons que la voiture est récupérée n'importe où en Belgique dans les 5 jours. Lors de la récupération, vous et le professionnel usell confirmez le transfert sur notre plateforme, et en quelques secondes, vous disposez du montant pour lequel l'accord a été conclu. C'est votre accord!",
        mission_title: "NOTRE MISSION",
        mission_text:
          "Nous voulons faire du marché des voitures d'occasion un lieu de commerce fiable, toujours en tenant compte de votre facilité d'utilisation. Nous visons à transformer les normes actuelles en de nouvelles valeurs dont nous sommes fiers en tant qu'entreprise.",
        vision_title: "NOTRE VISION",
        vision_text:
          "Notre plateforme est un programme innovant et entièrement automatisé qui met l'accent sur votre expérience utilisateur. La transparence et la convivialité sont les pierres angulaires de notre plateforme.",
        register_buyer_title: "ACHETER UN VÉHICULE",
        register_buyer_text:
          "Souhaitez-vous rejoindre notre réseau professionnel? Commencez votre demande ici.",
        register_seller_title: "VENDRE UN VÉHICULE",
        register_seller_text:
          "Voulez-vous vendre une voiture? Inscrivez-vous et commencez.",
        sold_auctions_title: "QU'AVONS-NOUS AIDÉ À VENDRE?",
        loading_auctions: "Chargement des enchères...",
        no_paid_auctions:
          "Il n'y a actuellement aucune enchère payée disponible.",
        create_account_title: "CRÉER UN COMPTE",
        create_account_text1:
          "Commencez par créer un compte sur notre plateforme.",
        create_account_text2:
          "Ensuite, vous serez guidé à travers le système pour que vous puissiez facilement trouver et entrer toutes les informations nécessaires sur votre voiture, puis ajouter quelques photos et vous avez terminé.",
        create_account_text3: "L'enchère peut commencer!",
        auctions_title: "ENCHÈRES",
        auctions_text1:
          "Vendez votre voiture en 5 jours! Votre voiture sera mise en enchère pour les professionnels. Vous pouvez accepter une offre immédiatement ou attendre 24 heures pour obtenir l'offre la plus élevée. Nous organisons la récupération sous 5 jours, partout en Belgique. Confirmez la vente lors de la collecte via notre plateforme.",
        auctions_text2:
          "Important : Assurez-vous de fournir une description honnête pour éviter des frais administratifs.",
        auctions_text3: "C'est votre accord!",

        // À PROPOS DE NOUS
        us_title: "Que représente",
        us_title2: "Usell",
        us_title3: "pour?",
        pillars_title: "Nos Piliers",
        pillars_text:
          "En tant que partenaire innovant sur le marché des voitures d'occasion, nous faisons la différence avec deux piliers : la transparence et la convivialité.",
        first_title: "Transparence (marché libre)",
        first_text:
          "Le client est roi, et nous prenons cela très au sérieux. L'espace commercial offert permet des retours et des connaissances honnêtes pour le client. Usell joue cartes sur table et est le seul partenaire qui fournit au client une vision honnête et réaliste de la valeur de sa voiture, totalement gratuit et sans engagement.",
        last_title: "Convivialité",
        last_text:
          "Inscrivez-vous et profitez d'une convivialité optimale. Avec toutes les nouvelles technologies émergentes dans le monde actuel et les changements rapides, nous ne sommes pas aveugles aux générations plus âgées. C'est pourquoi nous avons conçu notre plateforme en partant du principe que tout le monde doit pouvoir s'y retrouver. Vous serez guidé tout au long du processus pour que votre expérience soit fluide, agréable et enrichissante. Usell accorde une grande importance aux retours d'expérience, alors n'hésitez pas à nous envoyer vos commentaires ou questions.",
        last_text2:
          "Tout au long du processus, vous gardez le contrôle total. Dès que votre voiture est mise en ligne, vous êtes libre de choisir quelle offre accepter. Vous avez littéralement le temps en main. U.....sell = vous vendez. C'est votre accord!",
        // CONTACT
        contact_title: "CONTACTEZ-NOUS",
        contact_text1: "Avez-vous des questions ou des commentaires?",
        contact_text2:
          "Faites-le nous savoir, et nous vous répondrons dès que possible.",
        contact_title2: "ENVOYEZ-NOUS UN MESSAGE",
        contact_text3: "Vous avez une question? Consultez d'abord notre",
        contact_text4: "foire aux questions",
        contact_text5: "pour obtenir des réponses!",
        // FORMULAIRE DE CONTACT
        naam: "NOM",
        naam_placeholder: "Votre nom",
        email: "E-MAIL",
        email_placeholder: "Votre adresse e-mail",
        onderwerp: "OBJET",
        onderwerp_placeholder: "Objet",
        bericht: "MESSAGE",
        akkoord:
          "JE SUIS D'ACCORD AVEC LA POLITIQUE DE CONFIDENTIALITÉ DE USELL. JE COMPRENDS QUE USELL UTILISERA MES DONNÉES POUR RÉPONDRE À MES QUESTIONS.",
        contact_button: "ENVOYER",
        socials: "CONTACTEZ-NOUS PAR D'AUTRES PLATEFORMES",
        // NAVBAR
        nav1: "Accueil",
        nav2: "À propos de nous",
        nav3: "Contact",
        nav4: "Connexion",
        nav5: "Tableau de bord",
        nav6: "Déconnexion",
        footer1: "MENU",
        footer2: "Accueil",
        footer3: "À propos de nous",
        footer4: "Contact",
        footer5: "CONTACT",
        footer6: "LÉGAL",
        footer7: "Réclamation",
        footer8: "Conditions",
        footer9: "Politique de confidentialité",
        footer10: "Politique de cookies",
        footer_text: "© Copyright Usell. Tous droits réservés.",
        //UserNavigation SELLER
        navbar_title1: "Enchères",
        navbar_1: "Mes Enchères",
        navbar_2: "Créer Enchère",
        navbar_3: "Réposter Enchère",
        navbar_title2: "Calendrier",
        navbar_4: "Rendez-vous",
        navbar_5: "Agenda",
        navbar_title3: "Offres",
        navbar_title4: "Mon Profil",
        navbar_title5: "Stock",
        //UserNavigation BUYER
        navbar_6: "Enchères Gagnantes",
        navbar_7: "Prochaines Enchères",
        navbar_8: "Enchères en Cours",
        navbar_9: "Enchères Gagnées",
        navbar_10: "My Stock",
        navbar_11: "Available Stock",
        navbar_12: "Sell Stock",
        navbar_13: "Offres des Stock",
        //DASHBOARD
        dashboard_title: "Tableau de bord",
        dashboard1: "Enchères Actives",
        dashboard2: "Gérez et consultez vos enchères actives.",
        dashboard3: "Créer une Nouvelle Enchère",
        dashboard4: "Créez facilement une nouvelle enchère via le formulaire.",
        dashboard5: "Rendez-vous",
        dashboard6: "Gérez et consultez vos rendez-vous.",
        dashboard7: "Mon Profil",
        dashboard8: "Consultez et modifiez votre profil.",
        dashboard9: "Enchères en Cours",
        dashboard10: "Consultez vos enchères en cours.",
        dashboard11: "Enchères Gagnées",
        dashboard12: "Consultez vos enchères gagnées.",
        dashboard13: "Rendez-vous",
        dashboard14: "Gérez et consultez vos rendez-vous.",
        dashboard15: "Mon Profil",
        dashboard16: "Consultez et modifiez votre profil.",
        //WINNING-AUCTIONS
        winning_title: "ENCHÈRES GAGNANTES",
        winning_text:
          "Vous avez actuellement l'enchère la plus élevée sur ces enchères.",
        //FOLLOWING-AUCTIONS
        following_title: "ENCHÈRES SUIVANTES",
        following_text: "Consultez les enchères que vous suivez.",
        //LOPENDE VEILINGEN
        lopende_title: "ENCHÈRES",
        lopende_title2: "USELL",
        lopende_text1:
          "Consultez nos enchères. Trouvé quelque chose que vous aimez?",
        lopende_text2: "Cliquez dessus pour plus de détails.",
        stock_text1:
          "Consultez le stock de nos professionnels. Vous en avez trouvé un qui vous plaît ?",
        stock_text2: "Cliquez dessus pour plus de détails.",
        stock_text3:
          "Ceci est votre page de stock personnelle. Ici, vous pouvez voir un aperçu détaillé de tout le stock placé sur Usell.",
        //WON-AUCTIONS
        won_title: "ENCHÈRES GAGNÉES",
        won_text:
          "Vous avez gagné ces enchères en tant qu'enchérisseur le plus élevé.",
        //APPOINTMENT
        appointment1:
          "Veuillez attendre que l'acheteur confirme ou annule la vente après le rendez-vous.",
        appointment2: "Annuler",
        appointment3: "Accepter",
        appointment4: "Ouvrir l'agenda",
        appointment5:
          "Veuillez attendre que l'acheteur ait accepté un créneau horaire.",
        appointment6: "Ouvrir l'agenda",
        appointment7:
          "Veuillez attendre que le vendeur ait sélectionné 5 créneaux horaires.",
        appointment8: "Procéder à la vente",
        appointment9: "Veuillez attendre que l'autre partie accepte la vente.",
        appointment10: "Procéder à la vente",
        appointment11: "Veuillez attendre que l'autre partie accepte la vente.",
        appointment12: "Pas encore de date",
        appointment13: "Rendez-vous",
        appointment14: "À planifier",
        appointment15: "Rendez-vous planifiés",
        appointment16: "Rendez-vous terminés",
        //AGENDA
        agenda: "Agenda",
        agenda2: "Sélectionnez un rendez-vous",
        agenda3: "Rendez-vous",
        agenda4: "Sélectionnez des créneaux horaires pour votre rendez-vous",
        agenda5: "Soumettre des créneaux horaires",
        agenda6: "Créneaux horaires disponibles pour le rendez-vous",
        agenda7: "Accepter",
        agenda8: "Rejeter tout",
        agenda9: "Rendez-vous confirmés",
        agenda10: "Votre rendez-vous est confirmé pour",
        agenda11:
          "Le vendeur n'a pas encore soumis de créneaux horaires. Veuillez réessayer plus tard.",
        //PROFIEL
        profile: "Supprimer la photo de profil",
        profile1: "Profil",
        profile2: "Informations Personnelles",
        profile3: "Prénom",
        profile4: "Nom",
        profile5: "Email",
        profile6: "Date de Naissance",
        profile7: "Adresse",
        profile8: "Rue",
        profile9: "Numéro",
        profile10: "Code Postal",
        profile11: "Ville",
        profile12: "Province",
        profile13: "Pays",
        profile14: "Téléphone",
        profile15: "Modifier les informations personnelles",
        profile16: "Changer le mot de passe",
        profile17: "Modifier les informations personnelles",
        profile18: "Fermer",
        profile19: "Sauvegarder les modifications",
        profile20: "Changer le mot de passe",
        profile21: "Fermer",
        profile22: "Sauvegarder les modifications",
        profile23: "Modifier la photo de profil",
        profile24: "Annuler",
        profile26: "Supprimer le compte",
        profile25: "Sauvegarder",
        //MIJN VEILINGEN
        userveiling: "VOS ENCHÈRES",
        userveiling2: "Voici une liste de vos enchères.",
        userveiling3: "Enchères Actives",
        userveiling4: "Rafraîchir les enchères actives",
        userveiling5: "Historique",
        userveiling6: "Rafraîchir l'historique",
        userveiling7: "Rechercher des enchères...",
        userveiling8: "Vos enchères actives",
        userveiling9: "Votre historique des enchères",
        userveiling10: "En Cours",
        userveiling11: "Rafraîchir les enchères",
        userveiling12: "EN ATTENTE",
        userveiling13: "NON PAYÉ",
        userveiling14: "CONFIRMÉ",
        userveiling15: "ACCEPTÉ",
        userveiling16: "PAYÉ",
        userveiling17: "Voici vos enchères en cours de traitement",
        //BIEDINGEN
        bid: "Offres",
        bid2: "Mes enchères - Offres les plus élevées",
        bid3: "Nom de l'enchère",
        bid4: "Offre la plus élevée",
        bid5: "Actions",
        bid6: "Pas encore d'offres",
        bid7: "Accepter",
        bid8: "Rejeter",
        bid9: "Enchères Actives avec les Offres les Plus Élevées",
        bid10: "Mon stock - Offres les plus élevées",
        bid11: "Stock nom",
        bid12: "Mon stock termine - Offres les plus élevées",
        //RELIST AUCTION
        relist: "Enchères Bloquées",
        relist2: "Enchères Bloquées - Réposter",
        relist3: "Nom de l'enchère",
        relist4: "Statut",
        relist5: "Actions",
        relist6: "Réposter l'enchère",
        //CREATE AUCTION PAGE
        create: "Créer une nouvelle enchère",
        create2: "Détails de l'enchère",
        create3: "Nom *",
        create3_placeholder: "Nom de l'enchère",
        create4: "Prix de départ",
        create4_placeholder: "Prix de départ",
        create5: "Marque *",
        create5_placeholder: "Sélectionnez une marque",
        create6: "Modèle *",
        create6_placeholder: "Sélectionnez un modèle",
        create7: "Année *",
        create7_placeholder: "Année",
        create8: "Numéro de châssis *",
        create8_placeholder: "Numéro de châssis",
        create9: "Génération *",
        create9_placeholder: "Sélectionnez une génération",
        create10: "Série *",
        create10_placeholder: "Sélectionnez une série",
        create11: "Finition *",
        create11_placeholder: "Sélectionnez une finition",
        create12: "Couleur *",
        create12_placeholder: "Couleur",
        create13: "Nombre de portes *",
        create14: "Kilométrage *",
        create15: "Moteur *",
        create16: "Transmission",
        create16_placeholder1: "Automatique",
        create16_placeholder2: "Semi-automatique",
        create16_placeholder3: "Manuelle",
        create17: "Transmission",
        create17_placeholder1: "Traction avant",
        create17_placeholder2: "Traction arrière",
        create17_placeholder3: "Quatre roues motrices",
        create18: "Carburant",
        create18_placeholder1: "Diesel",
        create18_placeholder2: "Essence",
        create18_placeholder3: "Hybride",
        create18_placeholder4: "GPL",
        create18_placeholder5: "Électrique",
        create19: "Options",
        create19_placeholder: "Options (séparez-les par des virgules)",
        create20: "Dommages",
        create20_placeholder: "Dommages (séparez-les par des virgules)",
        create21: "Document de bord * (Max 1)",
        create22: "Description",
        create22_placeholder: "Description",
        create23: "Images (Maximum 10)",
        create24: "Financement disponible",
        create25: "Numéro de TVA du véhicule",
        create25_placeholder: "Numéro de TVA du véhicule",
        create26: "Adresse de rendez-vous",
        create27: "Rue",
        create28: "Numéro",
        create29: "Code postal",
        create30: "Ville",
        create31: "Province",
        create32: "Pays",
        create33: "Numéro de téléphone",
        create34: "CRÉER L'ENCHÈRE",
        //CARCARD
        car: "VENDU EN 1 JOUR",
        car2: "Terminé",
        //STOCK
        stock_title: "Mon Stock",
        stock_text:
          "Ceci est un aperçu de votre catalogue de stock personnel, ce qui vous permet de gérer votre stock facilement et de manière claire.",
        create43: "CRÉER UN STOCK",
        create44: "Créez un nouveau stock",
        create45: "Détails du Stock",
        //ADMINNAV
        admin_nav1: "Tableau de bord",
        admin_nav2: "Utilisateurs",
        admin_nav3: "Enchères",
        admin_nav4: "Voitures refusées",
        //COMPLETEPROFILEFORM
        complete_profile: "Mon profil",
        complete_profile1: "Terminez votre inscription",
        complete_profile2: "Prénom",
        complete_profile3: "Nom de famille",
        complete_profile4: "Date de naissance",
        complete_profile5: "Numéro de TVA",
        complete_profile6: "Complétez votre adresse complète",
        complete_profile7: "Rue",
        complete_profile8: "Numéro de maison",
        complete_profile9: "Code postal",
        complete_profile10: "Ville",
        complete_profile11: "Province",
        complete_profile12: "Pays",
        complete_profile13: "Numéro de téléphone",
        complete_profile14: "Terminez votre inscription",
        complete_profile15: "Entreprise/commerçant professionnel de voitures",
        complete_profile16: "Je m'inscris en tant qu'entreprise",
        complete_profile17:
          "Je m'inscris en tant que commerçant professionnel de voitures",
        complete_profile18: "J'accepte l'accord RGPD",
        complete_profile19: "J'accepte l'accord de confidentialité",
        complete_profile20: "J'accepte l'accord d'utilisateur",
        complete_profile21: "Modifier les informations personnelles",
        complete_profile22: "Changer le mot de passe",
        cookie: "Accepter les cookies",
        cookie1:
          "Nous utilisons des cookies pour personnaliser le contenu, offrir des fonctionnalités de médias sociaux et analyser notre trafic",
        cookie2: "En savoir plus",
        cookie3: "Accepter",
        cookie4: "Refuser",
        onboarding: "L'enregistrement auprès de Stripe a réussi !",
        onboarding1:
          "Merci d'avoir complété votre enregistrement. Vous pouvez désormais recevoir des paiements ou placer des offres sans aucun problème.",
        onboarding2:
          "Accédez à votre page d'enchères ou à la page des ventes aux enchères et réessayez d'accepter/placer une offre.",
        onboarding3: "Intégration refusée",
        onboarding4:
          "Désolé, votre processus d'intégration n'a pas été complété avec succès. Veuillez réessayer de vous inscrire ou contacter le support.",
        detail1: "Enchère la plus élevée actuelle:",
        detail2: "Aucune offre disponible",
        detail3: "GÉNÉRAL",
        detail4: "TRANSMISSIONS",
        detail5: "FINANCEMENT",
        detail6: "SYSTÈME TVA",
        detail7: "OPTIONS",
        detail8: "Aucune option disponible",
        detail9: "DOMMAGES",
        detail10: "Aucun dommage disponible",
        detail11: "DOCUMENTS",
        detail12: "OFFRES",
        detail13: "Enchère gagnante:",
        detail14: "Faire une offre",
        detail15: "Voir toutes les offres",
        detail16: "Placez votre offre",
        detail17: "Montant",
        detail18: "Frais de dossier:",
        detail19: "TVA Usell:",
        detail20: "TVA Voiture:",
        detail21: "Montant total:",
        detail22: "Annuler",
        detail23: "Placer une offre",
        detail24: "Toutes les offres",
        detail25: "Fermer",
        detail26: "Offre placée le:",
        detail27: "Informations supplémentaires",
        detail28: "Fermer",
        "veiling-gedaan": "Terminé",
        "stock-gedaan":"Terminé",
        "gelijkvormigheidsattest":"Certificat de Conformité",
        "inschrijvingsbewijs":"Certificat d'Immatriculation",
        "keuringsattest":"Certificat de Contrôle Technique",
        confirmRelist: "Voulez-vous vraiment relister cette enchère?",
        confirmRelistMessage: "Vous ne pourrez pas annuler cette action.",
        confirm: "Oui",
        cancel: "Non",
        navbar_14: "archiv� Stock",
        admin_nav5: "Stock",
        //FAQ FR
        footerFAQ: "des questions fr�quemment pos�es",
        faq_usell: "Qu'est-ce que Usell ?",
        faq_usell_answer:
          "Usell est une plateforme d'ench�res en ligne o� vous pouvez vendre votre voiture d'occasion. Nous offrons un moyen rapide et facile de vendre votre voiture � un prix �quitable.",

        faq_veiling:
          "Comment fonctionne la vente aux ench�res de voitures sur Usell ?",
        faq_veiling_answer:
          "Pour vendre votre voiture, inscrivez-vous et entrez les informations n�cessaires sur votre v�hicule. Ensuite, votre voiture sera plac�e dans une vente aux ench�res en ligne o� les acheteurs int�ress�s pourront ench�rir.",

        faq_kosten: "Quels sont les frais pour utiliser Usell ?",
        faq_kosten_answer:
          "Il n'y a aucun co�t pour vendre un v�hicule sur Usell. Seuls les professionnels qui ach�tent des v�hicules doivent payer des frais.",

        faq_niet_verkocht:
          "Que se passe-t-il si ma voiture n'est pas vendue sur Usell ?",
        faq_niet_verkocht_answer:
          "Si votre voiture n'est pas vendue via Usell, vous pouvez choisir de relancer la vente aux ench�res ou de retirer votre voiture de la plateforme.",

        faq_waarde_auto:
          "Comment puis-je estimer la valeur de ma voiture avant de la vendre sur Usell ?",
        faq_waarde_auto_answer:
          "Lors de l'inscription de votre v�hicule en ligne, vous devrez indiquer un prix de vente souhait�. Le montant le plus �lev� que vous obtiendrez sera un prix conforme au march� au moment o� la vente aux ench�res est en cours.",

        faq_klantenservice:
          "Puis-je contacter le service client si j'ai des questions ou des probl�mes ?",
        faq_klantenservice_answer:
          "Oui, si vous avez des questions ou des probl�mes, vous pouvez contacter notre service client par e-mail ou par t�l�phone. Notre �quipe est pr�te � vous aider.",

        faq_welke_wagens:
          "Tout type de voiture peut-il �tre vendu sur Usell.be ?",
        faq_welke_wagens_answer:
          "Oui, toute voiture appartenant l�galement au vendeur peut �tre mise en vente sur Usell.be.",

        faq_schade:
          "Que se passe-t-il si je ne d�clare pas tous les dommages, ce qui entra�ne un litige ?",
        faq_schade_answer:
          "Si le professionnel se retire de l'achat, il doit fournir une preuve. Si vous avez omis des informations qui affectent n�gativement la valeur de la voiture, la vente peut �tre annul�e.",

        faq_meerdere_wagens: "Puis-je proposer plusieurs voitures sur Usell ?",
        faq_meerdere_wagens_answer:
          "Oui, tant que vous �tes le propri�taire l�gal, vous pouvez mettre plusieurs voitures en vente sur Usell.be.",

        faq_bedrijfswagens: "Puis-je vendre des voitures d'entreprise ?",
        faq_bedrijfswagens_answer:
          "Usell prend en charge les v�hicules immatricul�s � la TVA. Indiquez votre num�ro de TVA lors de l'inscription et utilisez le nom de votre entreprise comme identification.",

        faq_bod_weigeren: "Puis-je refuser une offre ?",
        faq_bod_weigeren_answer:
          "Oui, chez Usell, vous avez un contr�le total. Vous d�cidez d'accepter ou de refuser une offre.",

        faq_verkoop_verzekerd: "Ma vente est-elle s�curis�e ?",
        faq_verkoop_verzekerd_answer:
          "Une fois que vous acceptez une offre, l'inspection finale est r�alis�e par le professionnel. Si le v�hicule correspond � la description, la vente se poursuit avec l'accord des deux parties.",

        faq_auto_keuren:
          "Dois-je faire inspecter mon v�hicule avant la vente ?",
        faq_auto_keuren_answer:
          "Non, une inspection avant la vente est uniquement requise si vous vendez � un particulier. Comme seuls des professionnels ach�tent sur Usell, vous devez uniquement fournir le dernier certificat d'inspection.",

        faq_herroepingsrecht: "Le droit de r�tractation s'applique-t-il ?",
        faq_herroepingsrecht_answer:
          "Non, puisque vous vendez � un professionnel, celui-ci est tenu d'inspecter la voiture sur place. Une fois la vente confirm�e, il ne peut pas invoquer le droit de r�tractation.",

        faq_aanmelden_probleem: "Je n'arrive pas � me connecter ?",
        faq_aanmelden_probleem_answer:
          "Si vous ne pouvez pas vous connecter, essayez � nouveau et v�rifiez votre e-mail pour plus d'informations. Si le probl�me persiste, contactez-nous � info@usell.be.",

        faq_betalingen_veilig:
          "Les paiements sont-ils s�curis�s et comment sont-ils trait�s ?",
        faq_betalingen_veilig_answer:
          "Notre partenaire Stripe garantit des paiements s�curis�s. Une fois la vente confirm�e, Usell et Stripe traitent le paiement rapidement et en toute s�curit�.",

        faq_transactie_niet_direct:
          "Que faire si la transaction n'est pas imm�diatement ex�cut�e ?",
        faq_transactie_niet_direct_answer:
          "Une fois la vente confirm�e via votre tableau de bord priv�, le professionnel doit faire de m�me. Contactez-nous en cas de probl�me avec le traitement du paiement.",

        faq_prijsonderhandelingen:
          "Y a-t-il encore des n�gociations de prix apr�s avoir accept� une offre ?",
        faq_prijsonderhandelingen_answer:
          "Non. Lors du contr�le du v�hicule, vous et le professionnel confirmez la transaction sur la plateforme, ce qui emp�che toute n�gociation ult�rieure du prix.",

        faq_privacy:
          "Mes donn�es personnelles sont-elles en s�curit� avec Usell ?",
        faq_privacy_answer:
          "Usell s'engage � prot�ger vos donn�es conform�ment aux r�glementations en vigueur et aux mesures de s�curit� appropri�es.",

        faq_thuisadres:
          "Dois-je fournir mon adresse personnelle comme lieu de r�cup�ration ?",
        faq_thuisadres_answer:
          "Non, ce n'est pas obligatoire. Vous pouvez entrer une autre adresse de r�cup�ration pour plus de confort et de s�curit�.",

        faq_professionals_betrouwbaar: "Les professionnels sont-ils fiables ?",
        faq_professionals_betrouwbaar_answer:
          "Usell v�rifie soigneusement tous les professionnels et les �value en fonction des avis clients afin de garantir leur fiabilit�.",
        trader_disclaimer:
          "Si le vendeur accepte votre offre, vous avez remport� l'ench�re et acquis une option d'achat selon les conditions convenues de l'ench�re. Le commer�ant est seul responsable des d�fauts visibles et cach�s. S'il d�cide de prendre le v�hicule apr�s le rendez-vous, cela est consid�r� comme une acceptation de l'achat dans son �tat actuel.",
        trader_disclaimer_watchout:
          "Attention:",
      },
    },
  },
  lng: "nl",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
