import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Table,
  Button,
  Spinner,
  Modal,
  Tooltip,
  OverlayTrigger,
  Alert,
} from "react-bootstrap";
import { UserNavigation } from "../../components/UserNavigation";
import { AuthContext } from "../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from "framer-motion";

export function RelistAuctionPage() {
  const [auctions, setAuctions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [relistLoading, setRelistLoading] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const token = apiAccessToken;
  const user = userMetadata;
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = [t("relist"), "USELL"];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    const fetchCancelledAuctions = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/user/${user.id}/disabled-auctions/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        setLoading(true);
        const response = await fetch(URL, options);
        if (!response.ok) throw new Error(t("fetchError"));
        const data = await response.json();
        setAuctions(data);
      } catch (error) {
        console.error(error);
        setError(t("fetchErrorMessage"));
      } finally {
        setLoading(false);
      }
    };

    fetchCancelledAuctions();
  }, [token, user, t]);

  const handleRelistClick = (auction) => {
    setSelectedAuction(auction);
    setShowModal(true);
  };

  const relistAuction = async () => {
    if (!selectedAuction) return;
    const auctionId = selectedAuction.id;
    const url = `https://usell.be/api/relist-auction/${auctionId}/`;
    const options = {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      setRelistLoading(auctionId);
      const response = await fetch(url, options);
      console.log("Response status:", response.status);

      let responseData;
      try {
        responseData = await response.json();
      } catch (jsonError) {
        console.error("Fout bij JSON-parsen:", jsonError);
        throw new Error("Ongeldige serverresponse");
      }

      console.log("Backend response:", responseData);

      if (!response.ok) {
        throw new Error(
          responseData.error || responseData.message || t("relistError")
        );
      }

      setAuctions((prev) => prev.filter((auction) => auction.id !== auctionId));
      setSuccessMessage("kavel succesvol opnieuw actief geplaatst");
    } catch (error) {
      console.error("Relist error:", error.message);
      setError(error.message);
    } finally {
      setRelistLoading(null);
      setShowModal(false);
    }
  };

  return (
    <>
      <UserNavigation />
      <div className="create-auction-hero">
        <div className="hero-background create-auction-background"></div>
        <Container className="content content-padding-top-lg content-padding-bottom-lg">
          <motion.h1
            className="appointments-title"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            {dynamicTitle}
          </motion.h1>
        </Container>
      </div>

      <Container className="mt-5">
        {successMessage && (
          <Alert
            variant="success"
            onClose={() => setSuccessMessage("")}
            dismissible
          >
            {successMessage}
          </Alert>
        )}
        <h2 className="mb-4">{t("relist2")}</h2>
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>{t("loadingAuctions")}</p>
          </div>
        ) : error ? (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        ) : (
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>{t("relist3")}</th>
                <th>{t("relist4")}</th>
                <th>{t("relist5")}</th>
              </tr>
            </thead>
            <motion.tbody layout>
              <AnimatePresence>
                {auctions.map((auction) => (
                  <motion.tr
                    key={auction.id}
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 10 }}
                  >
                    <td>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{t("auctionNameTooltip")}</Tooltip>}
                      >
                        <span>{auction.name}</span>
                      </OverlayTrigger>
                    </td>
                    <td>{auction.status}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleRelistClick(auction)}
                        disabled={relistLoading === auction.id}
                      >
                        {relistLoading === auction.id ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          t("relist6")
                        )}
                      </Button>
                    </td>
                  </motion.tr>
                ))}
              </AnimatePresence>
            </motion.tbody>
          </Table>
        )}
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("confirmRelist")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAuction && (
            <p>
              {t("confirmRelistMessage", { auctionName: selectedAuction.name })}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t("cancel")}
          </Button>
          <Button variant="primary" onClick={relistAuction}>
            {t("confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
