import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Table, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { AdminNavbar } from "../../components/AdminNav";
import { AuthContext } from "../../contexts/AuthContext";
import AuctionDetailModal from '../../components/AuctionDetailModal';
import CarDetailModal from '../../components/CarDetailModal';

function AdminAuctionsPage() {
    return (
        <>
            <AdminNavbar />
            <AdminAuctions />
        </>
    );
}

const AdminAuctions = () => {
    const [auctions, setAuctions] = useState(null);
    const [filterStatus, setFilterStatus] = useState("");
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);
    const [showAuctionModal, setShowAuctionModal] = useState(false);
    const [showCarModal, setShowCarModal] = useState(false);
    const { apiAccessToken } = useContext(AuthContext);

    useEffect(() => {
        fetchData();
    }, [filterStatus, apiAccessToken]);

    const fetchData = async () => {
        let URL = "https://usell.be/api/auctions/";
        if (filterStatus) {
            URL += `?status=${filterStatus}`;
        }
        const token = apiAccessToken;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        try {
            const response = await fetch(URL, options);
            if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
            const data = await response.json();
            setAuctions(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleShowAuctionDetails = (auction) => {
        setSelectedAuction(auction);
        setShowAuctionModal(true);
    };

    const handleShowCarDetails = (car) => {
        setSelectedCar(car);
        setShowCarModal(true);
    };

    const handleCloseAuctionModal = () => {
        setShowAuctionModal(false);
        setSelectedAuction(null);
    };

    const handleCloseCarModal = () => {
        setShowCarModal(false);
        setSelectedCar(null);
    };

    if (!auctions) return "Loading";

    return (
        <Container>
            <Row>
                <Col sm={12} md={3}>
                    <aside>
                        <h4>Filters</h4>
                        <Form.Group>
                            <Form.Label>Status:</Form.Label>
                            <Form.Control as="select" value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
                                <option value="">All</option>
                                <option value="ONGOING">Ongoing</option>
                                <option value="ACCEPTED">Accepted</option>
                                <option value="PENDING">Pending</option>
                                <option value="UNPAID">Unpaid</option>
                                <option value="PAID">Paid</option>
                                <option value="FINISHED">Finished</option>
                                <option value="CANCELLED">Cancelled</option>
                                <option value="CONFIRMED">Confirmed</option>
                                <option value="TEKOOP">Tekoop</option>
                                <option value="VERKOCHT">Verkocht</option>
                                <option value="SUSPENDED">Suspended</option>
                            </Form.Control>
                        </Form.Group>
                    </aside>
                </Col>
                <Col sm={12} md={9}>
                    <Table responsive className="table table-striped table-bordered mt-3">
                        <thead>
                            <tr>
                                <th>Owner</th>
                                <th>Status</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th>Actions</th>
                                <th>Car</th>
                            </tr>
                        </thead>
                        <tbody>
                            {auctions.map((auction, index) => (
                                <tr key={index} style={{ cursor: 'pointer' }}>
                                    <td>{auction.owner.first_name} {auction.owner.last_name}</td>
                                    <td>{auction.status}</td>
                                    <td>{auction.start_date}</td>
                                    <td>{auction.end_date}</td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            color="gray"
                                            onClick={() => handleShowAuctionDetails(auction)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                    <td>
                                        <FontAwesomeIcon
                                            icon={faEye}
                                            color="gray"
                                            onClick={() => handleShowCarDetails(auction.car)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <AuctionDetailModal
                show={showAuctionModal}
                handleClose={handleCloseAuctionModal}
                auction={selectedAuction}
            />

            <CarDetailModal
                show={showCarModal}
                handleClose={handleCloseCarModal}
                car={selectedCar}
            />
        </Container>
    );
};

export default AdminAuctionsPage;