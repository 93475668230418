import { Col } from "react-bootstrap";
import { CarCard } from "./CarCard";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export function CarList({ auctions }) {

    const navigate = useNavigate();

    const [followingCounts, setFollowingCounts] = useState({});

    const updateFollowingCount = (auctionId, count) => {
        setFollowingCounts(prev => ({ ...prev, [auctionId]: count }));
    };

    
    if (!auctions) return "Loading";


    return (
        <>
            {auctions.map(auction => (
                <Col lg={4} md={6} className="mb-4" key={auction.id}>
                        <CarCard
                            title={auction.name}
                            buildyear={auction.car.build_year}
                            mileage={auction.car.kilometers}
                            fuelType={auction.car.fuel_type}
                            image={auction.image_url || "/images/cars/opel.webp"}
                            endDate={auction.end_date}
                            highestBid={auction.highest_bid}
                            auctionId={auction.id}
                            followingCount={followingCounts[auction.id] || auction.following_count}
                            visitCount={auction.visit_count || auction.visits}
                            onClick={() => navigate(`/veiling/${auction.id}`)}
                            updateFollowingCount={(count) => updateFollowingCount(auction.id, count)}
                        />
                </Col >
            ))
            }
        </>
    );
}