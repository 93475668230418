import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

export function PasswordForm({ handlePasswordChange }) {
    const [newPassword, setNewPassword] = useState("");

    const onSubmit = (e) => {
        e.preventDefault();
        handlePasswordChange(newPassword);
    };

    return (
        <Form onSubmit={onSubmit}>
            <Form.Group>
                <Form.Label>Nieuw Wachtwoord</Form.Label>
                <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                />
            </Form.Group><br></br>
            <Button variant="primary" type="submit">
                Wachtwoord Wijzigen
            </Button>
        </Form>
    );
}