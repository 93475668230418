import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import React from "react";

export function AdminNavbar() {
    const location = useLocation();
    const { t } = useTranslation();
    const isActive = (path) => {
        return location.pathname === path;
    };

    return (
        <div className={"pb-4"}>
            <Nav className={"bg-dark justify-content-center"} variant="pills" defaultActiveKey="/admin">
                <Nav.Item>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                    >
                        <Nav.Link
                            className={"bg-dark text-light"}
                            as={Link}
                            to="/admin"
                            active={isActive("/admin")}
                        >
                            {t('admin_nav1')}
                        </Nav.Link>
                    </motion.div>
                </Nav.Item>
                <Nav.Item>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                    >
                        <Nav.Link
                            className={"bg-dark text-light"}
                            as={Link}
                            to="/admin/gebruikers"
                            active={isActive("/admin/gebruikers")}
                        >
                            {t('admin_nav2')}
                        </Nav.Link>
                    </motion.div>
                </Nav.Item>
                <Nav.Item>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <Nav.Link
                            className={"bg-dark text-light"}
                            as={Link}
                            to="/admin/veilingen"
                            active={isActive("/admin/veilingen")}
                        >
                            {t('admin_nav3')}
                        </Nav.Link>
                    </motion.div>
                </Nav.Item>
                <Nav.Item>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                    >
                        <Nav.Link
                            className={"bg-dark text-light"}
                            as={Link}
                            to="/admin/afgekeurde-wagens"
                            active={isActive("/admin/afgekeurde-wagens")}
                        >
                            {t('admin_nav4')}
                        </Nav.Link>
                    </motion.div>
                </Nav.Item>
                <Nav.Item>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <Nav.Link
                            className={"bg-dark text-light"}
                            as={Link}
                            to="/admin/stock"
                            active={isActive("/admin/stock")}
                        >
                            {t('admin_nav5')}
                        </Nav.Link>
                    </motion.div>
                </Nav.Item>
            </Nav>
        </div>
    );
}