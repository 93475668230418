import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { AdminNavbar } from "../../components/AdminNav";
import { AuthContext } from "../../contexts/AuthContext";
import StockDetailModal from "../../components/StockDetailModal";
import CarDetailModal from "../../components/CarDetailModal";

function AdminStockPage() {
  return (
    <>
      <AdminNavbar />
      <AdminStock />
    </>
  );
}

const AdminStock = () => {
  const [stockItems, setStockItems] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");
  const [selectedStock, setSelectedStock] = useState(null);
  const [selectedCar, setSelectedCar] = useState(null);
  const [showStockModal, setShowStockModal] = useState(false);
  const [showCarModal, setShowCarModal] = useState(false);
  const { apiAccessToken } = useContext(AuthContext);

  useEffect(() => {
    fetchData();
  }, [filterStatus, apiAccessToken]);

  const fetchData = async () => {
    let URL = "https://usell.be/api/stock/manage";
    if (filterStatus) {
      URL += `?status=${filterStatus}`;
    }
    const options = {
      method: "GET",
      headers: {
        authorization: `Bearer ${apiAccessToken}`,
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok)
        throw new Error("HTTP ERROR! STATUS: " + response.status);
      const data = await response.json();
      setStockItems(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowStockDetails = (stockItem) => {
    setSelectedStock(stockItem);
    setShowStockModal(true);
  };

  const handleShowCarDetails = (car) => {
    setSelectedCar(car);
    setShowCarModal(true);
  };

  const handleCloseStockModal = () => {
    setShowStockModal(false);
    setSelectedStock(null);
  };

  const handleCloseCarModal = () => {
    setShowCarModal(false);
    setSelectedCar(null);
  };

  if (!stockItems) return "Loading";

  return (
    <Container>
      <Row>
        <Col sm={12} md={3}>
          <aside>
            <h4>Filters</h4>
            <Form.Group>
              <Form.Label>Status:</Form.Label>
              <Form.Control
                as="select"
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
              >
                <option value="">All</option>
                <option value="TEKOOP">Te Koop</option>
                <option value="VERKOCHT">Verkocht</option>
                <option value="PENDING">Pending</option>
                <option value="CANCELLED">Cancelled</option>
                <option value="SUSPENDED">Suspended</option>
              </Form.Control>
            </Form.Group>
          </aside>
        </Col>
        <Col sm={12} md={9}>
          <Table responsive className="table table-striped table-bordered mt-3">
            <thead>
              <tr>
                <th>Owner</th>
                <th>Status</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Stock</th>
                <th>Car</th>
              </tr>
            </thead>
            <tbody>
              {stockItems.map((stockItem, index) => (
                <tr key={index} style={{ cursor: "pointer" }}>
                  <td>
                    {stockItem.owner.first_name} {stockItem.owner.last_name}
                  </td>
                  <td>{stockItem.status}</td>
                  <td>{stockItem.start_date}</td>
                  <td>{stockItem.end_date}</td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEye}
                      color="gray"
                      onClick={() => handleShowStockDetails(stockItem)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                  <td>
                    <FontAwesomeIcon
                      icon={faEye}
                      color="gray"
                      onClick={() => handleShowCarDetails(stockItem.car)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <StockDetailModal
        show={showStockModal}
        handleClose={handleCloseStockModal}
        stockItem={selectedStock}
      />

      <CarDetailModal
        show={showCarModal}
        handleClose={handleCloseCarModal}
        car={selectedCar}
      />
    </Container>
  );
};

export default AdminStockPage;
