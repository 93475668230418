import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import {
  Button,
  Carousel,
  Col,
  Form,
  Modal,
  Row,
  Card,
  Collapse,
} from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faCrown,
  faCar,
  faTachometerAlt,
  faDoorOpen,
  faCog,
  faGasPump,
  faTools,
  faChevronDown,
  faChevronUp,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../contexts/AuthContext";
import { UserNavigation } from "../components/UserNavigation";
import { FaInfoCircle, FaClock } from "react-icons/fa";
import moment from "moment-timezone";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";

export function StockDetailPage(props) {
  const { id } = useParams();
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const user = userMetadata;
  const [stockItem, setStockItem] = useState(null);
  const [show, setShow] = useState(false);
  const [formAmount, setFormAmount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showBids, setShowBids] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openDamages, setOpenDamages] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [btwCarPercentage, setBtwCarPercentage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const { t } = useTranslation();
  const [applicationFee, setApplicationFee] = useState(0);
  const [btwUsell, setBtwUsell] = useState(0);
  const [btwCar, setBtwCar] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [visitCount, setVisitCount] = useState(0);

  useEffect(() => {
    if (!userMetadata || !userMetadata.id) return;

    const URL = `https://usell.be/api/stock/${id}/visit/`;
    const token = apiAccessToken;

    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id: userMetadata.id }),
    };

    const updateVisitCount = async () => {
      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
        const data = await response.json();
        setVisitCount(data.visits);
      } catch (error) {
        console.error("Fout bij updaten van bezoekersteller", error);
      }
    };

    updateVisitCount();
  }, [id, apiAccessToken, userMetadata]);

  const handleClose = () => {
    setShow(false);
    setErrorMessage("");
  };
  const handleShow = () => setShow(true);
  const handleShowBids = () => setShowBids(true);
  const handleCloseBids = () => setShowBids(false);
  const handleModalOpen = (content) => {
    setModalContent(content);
    setShowModal(true);
  };
  const handleModalClose = () => setShowModal(false);

  const addBid = async (e) => {
    e.preventDefault();
    const URL = `https://usell.be/api/stock/add-bid/${id}/`;
    const token = apiAccessToken;
    const options = {
      method: "PATCH",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        stock_bids: [
          {
            user_id: user.id,
            amount: formAmount,
            date: new Date().toISOString(),
          },
        ],
      }),
    };

    try {
      const response = await fetch(URL, options);
      const data = await response.json();

      if (response.status === 403 || response.status === 302) {
        if (data.onboarding_url) {
          alert(
            "U moet eerst uw Stripe-account activeren. U wordt doorgestuurd."
          );
          window.location.href = data.onboarding_url;
          return;
        }
      }

      if (!response.ok) {
        if (data.error && data.min_increment) {
          setErrorMessage(
            `Uw bod moet minimaal €${data.min_increment} hoger zijn dan het huidige hoogste bod.`
          );
        } else {
          setErrorMessage(data.error || "Er is een fout opgetreden.");
        }
        return;
      }

      setStockItem(data.stock_data);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const getBtwStelselText = () => {
    if (!stockItem || !stockItem.car) return "Onbekend";

    const carBtw = parseFloat(stockItem.car.car_btw);
    console.log(stockItem.car.car_btw);

    if (carBtw === 0) return "0%";
    if (carBtw === 10.5) return "50%";
    if (carBtw === 21) return "100%";
    return "Onbekend";
  };

  useEffect(() => {
    const URL = `https://usell.be/api/stock/${id}/`;
    const token = apiAccessToken;
    const options = {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    const fetchData = async () => {
      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
        const data = await response.json();
        console.log("Fetched stock data:", data);
        setStockItem(data);

        const endTime = moment(data.end_date);
        const now = moment();
        const remainingTime = endTime.diff(now, "seconds");
        setTimeRemaining(remainingTime > 0 ? remainingTime : 0);

        if (data.car && data.car.car_btw) {
          setBtwCarPercentage(data.car.car_btw);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id, apiAccessToken]);

  useEffect(() => {
    const calculateCosts = (bidAmount) => {
      if (bidAmount > 0) {
        const applicationFeePercentage = bidAmount > 90000 ? 9.5 : 6.5;
        const appFee = (bidAmount * applicationFeePercentage) / 100;
        const btwU = appFee * 0.21;
        const appFeeExclBtw = appFee + btwU;
        const btwC = (bidAmount * btwCarPercentage) / 100;
        const total = bidAmount + appFeeExclBtw + btwC;

        setApplicationFee(appFee);
        setBtwUsell(btwU);
        setBtwCar(btwC);
        setTotalAmount(total);
      } else {
        setApplicationFee(0);
        setBtwUsell(0);
        setBtwCar(0);
        setTotalAmount(0);
      }
    };

    calculateCosts(formAmount);
  }, [formAmount, btwCarPercentage, stockItem]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  const formatTimeRemaining = (timeRemaining) => {
    if (timeRemaining === 0) return t("stock-gedaan");
    const duration = moment.duration(timeRemaining, "seconds");
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  if (!stockItem) {
    return (
      <div className="spinner-container">
        <Spinner animation="border" role="status" className="spinner">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const highestBid =
    stockItem.stock_bids && stockItem.stock_bids.length > 0
      ? Math.max(...stockItem.stock_bids.map((bid) => bid.amount))
      : null;

  const winningBid =
    stockItem.stock_bids && stockItem.stock_bids.length > 0
      ? stockItem.stock_bids[stockItem.stock_bids.length - 1]
      : null;

  const DocumentStatus = ({ label, url }) => (
    <div className="d-flex align-items-center me-4">
      <span className="me-2">{label}</span>
      <FontAwesomeIcon
        icon={url ? faCheckCircle : faTimesCircle}
        style={{ color: url ? "green" : "red" }}
      />
    </div>
  );

  return (
    <>
      <UserNavigation />
      <motion.div
        className="detail-auctions-hero"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div
          className={"detail-auctions-background"}
          style={{
            backgroundImage: "url('/images/cars/audi.jpeg')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <Container
          className={
            "content content-padding-top-lg content-padding-bottom-lg mobiel-auction"
          }
        >
          <motion.h2
            className="shadow-text auction-detail-page-p"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {stockItem.name || "OPEL CORSA TUNING"}
          </motion.h2>
          <motion.h4
            className="fw-bold shadow-text auction-detail-page-p"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.8 }}
          >
            {t("detail1")}
            {highestBid !== null ? (
              <>
                <FontAwesomeIcon icon={faEuroSign} />
                {highestBid}
              </>
            ) : (
              t("detail2")
            )}
          </motion.h4>
        </Container>
      </motion.div>
      <Container className={"content detail"}>
        <Row>
          <Col lg={5}>
            <motion.div
              className="carousel-container"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
            >
              <Carousel className={"car-carousel"}>
                {stockItem.images.map((image, index) => (
                  <Carousel.Item interval={1000} key={index}>
                    <img
                      src={image.url}
                      alt="car"
                      className="rounded-carousel-img"
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </motion.div>
          </Col>
          <Col lg={7}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: { opacity: 0, y: 10 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delayChildren: 0.3,
                    staggerChildren: 0.2,
                  },
                },
              }}
            >
              <Col
                className="text-end"
                style={{ fontSize: "2rem", color: "#2196F3" }}
              >
                <div className="d-flex align-items-center">
                  <FaClock className="me-2" />
                  <span className="shadow-text">
                    {formatTimeRemaining(timeRemaining)}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <h5 className="visit-counter">
                    {visitCount} {t("visits")}
                  </h5>
                </div>
              </Col>
              <Row>
                <Col lg={6}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <h4 className="shadow-text" style={{ color: "#2196F3" }}>
                        {t("detail3")}
                      </h4>
                      <ul className="car-specs-list">
                        <li>
                          <FontAwesomeIcon icon={faCar} />{" "}
                          {stockItem.car.brand_name} {stockItem.car.model}{" "}
                          {stockItem.car.build_year}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCar} />{" "}
                          {stockItem.car.generation} {stockItem.car.series}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faTachometerAlt} />{" "}
                          {stockItem.car.kilometers} KM
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faDoorOpen} />{" "}
                          {stockItem.car.number_of_doors} deur(en)
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <h4 className="shadow-text" style={{ color: "#2196F3" }}>
                        {t("detail4")}
                      </h4>
                      <ul className="car-specs-list">
                        <li>
                          <FontAwesomeIcon icon={faCog} />{" "}
                          {stockItem.car.engine}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCog} /> {stockItem.car.drive}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faTools} />{" "}
                          {stockItem.car.transmission}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faGasPump} />{" "}
                          {stockItem.car.fuel_type}
                        </li>
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4
                          className="shadow-text"
                          style={{ color: "#2196F3" }}
                        >
                          {t("detail5")}
                        </h4>
                        <div className="d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={
                              stockItem.financing
                                ? faCheckCircle
                                : faTimesCircle
                            }
                            style={{
                              color: stockItem.financing ? "green" : "red",
                              marginRight: "8px",
                            }}
                          />
                          <FaInfoCircle
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleModalOpen(
                                "Financiering informatie: beschikbaar afhankelijk van financieringsstatus van de auto."
                              )
                            }
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4
                          className="shadow-text"
                          style={{ color: "#2196F3" }}
                        >
                          {t("detail6")}
                        </h4>
                        <div className="d-flex align-items-center">
                          {getBtwStelselText(stockItem.car.car_btw)}

                          <FaInfoCircle
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleModalOpen("tekst btw stelsel")}
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4
                          className="shadow-text"
                          style={{ color: "#2196F3" }}
                        >
                          {t("detail7")}
                        </h4>
                        <FontAwesomeIcon
                          icon={openOptions ? faChevronUp : faChevronDown}
                          onClick={() => setOpenOptions(!openOptions)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <Collapse in={openOptions}>
                        <ul className="car-specs-list">
                          {stockItem.car.options.length > 0 ? (
                            stockItem.car.options.map((option) => (
                              <li key={option.id}>{option.description}</li>
                            ))
                          ) : (
                            <li>{t("detail8")}</li>
                          )}
                        </ul>
                      </Collapse>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4
                          className="shadow-text"
                          style={{ color: "#2196F3" }}
                        >
                          {t("detail9")}
                        </h4>
                        <FontAwesomeIcon
                          icon={openDamages ? faChevronUp : faChevronDown}
                          onClick={() => setOpenDamages(!openDamages)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <Collapse in={openDamages}>
                        <ul className="car-specs-list">
                          {stockItem.car.damages.length > 0 ? (
                            stockItem.car.damages.map((damage) => (
                              <li key={damage.id}>{damage.description}</li>
                            ))
                          ) : (
                            <li>{t("detail10")}</li>
                          )}
                        </ul>
                      </Collapse>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="car-specs-card">
                    <Card.Body>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4
                          className="shadow-text"
                          style={{ color: "#2196F3" }}
                        >
                          {t("detail11")}
                        </h4>
                      </div>
                      <div className="d-flex mt-3">
                        <DocumentStatus
                          label={t("gelijkvormigheidsattest")}
                          url={stockItem.car.boorddocument_url}
                        />
                        <DocumentStatus
                          label={t("inschrijvingsbewijs")}
                          url={stockItem.car.kenteken_url}
                        />
                        <DocumentStatus
                          label={t("keuringsattest")}
                          url={stockItem.car.kenteken2_url}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </motion.div>
          </Col>
        </Row>
        <Row className="my-4">
          <Col sm={9}>
            <h4
              className="shadow-text auction-detail-page"
              style={{ color: "#2196F3" }}
            >
              {t("detail12")}
            </h4>
            {winningBid && (
              <div className="winning-bid auction-detail-page-p2">
                <FontAwesomeIcon icon={faCrown} className="text-warning me-2" />
                {t("detail13")} <FontAwesomeIcon icon={faEuroSign} />
                {winningBid.amount}
              </div>
            )}
          </Col>
          <Col sm={3} className="d-flex justify-content-end">
            {user.id !== stockItem.owner.id && (
              <Button
                variant="primary"
                onClick={handleShow}
                className="me-2"
                disabled={stockItem.status !== "TEKOOP"}
              >
                {t("detail14")}
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={handleShowBids}
              className="ms-2"
            >
              {t("detail15")}
            </Button>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={addBid}>
          <Modal.Header closeButton>
            <Modal.Title>{t("detail16")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBidAmount">
              <Form.Label>{t("detail17")}</Form.Label>
              <Form.Control
                type="number"
                placeholder={
                  isNaN(parseFloat(highestBid))
                    ? "0.00"
                    : parseFloat(highestBid).toFixed(2)
                }
                value={formAmount}
                onChange={(e) => setFormAmount(parseFloat(e.target.value))}
                autoFocus
              />
            </Form.Group>

            <div className="mt-3">
              <p>
                {t("detail18")} <FontAwesomeIcon icon={faEuroSign} />
                {applicationFee.toFixed(2)} ( .incl BTW Usell)
              </p>
              <p>
                {t("detail19")} <FontAwesomeIcon icon={faEuroSign} />
                {btwUsell.toFixed(2)}
              </p>
              <p>
                {t("detail20")} <FontAwesomeIcon icon={faEuroSign} />
                {btwCar.toFixed(2)}
              </p>
              <p>
                <strong>
                  {t("detail21")} <FontAwesomeIcon icon={faEuroSign} />
                  {totalAmount.toFixed(2)}
                </strong>
              </p>
            </div>

            {errorMessage && <p className="text-danger">{errorMessage}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t("detail22")}
            </Button>
            <Button type="submit" variant="primary">
              {t("detail23")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showBids} onHide={handleCloseBids}>
        <Modal.Header closeButton>
          <Modal.Title>{t("detail24")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {stockItem.stock_bids.map((bid, index) => (
              <li key={index}>
                <FontAwesomeIcon icon={faEuroSign} />
                {bid.amount} ({t("detail26")}{" "}
                {new Date(bid.date).toLocaleString()})
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBids}>
            {t("detail25")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("detail27")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            {t("detail28")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
