import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
  Alert,
} from "react-bootstrap";
import { UserNavigation } from "../components/UserNavigation";
import { AuthContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./AgendaPage.css";
import "../appointment-buyer.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import BelgiumMap from "../components/BelgiumMap";

export function TestPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [slots, setSlots] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { userMetadata, apiAccessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const user = userMetadata;
  const isSeller =
    (selectedAppointment && selectedAppointment.seller === user.id) ||
    (userMetadata &&
      (userMetadata.type === "seller" || userMetadata.type === "company"));
  const token = apiAccessToken;
  const { t } = useTranslation();
  const [dynamicTitle, setDynamicTitle] = useState("USELL");

  useEffect(() => {
    const titleOptions = [t("agenda"), "USELL"];
    let index = 0;

    const interval = setInterval(() => {
      index = (index + 1) % titleOptions.length;
      setDynamicTitle(titleOptions[index]);
    }, 2000);

    return () => clearInterval(interval);
  }, [t]);

  useEffect(() => {
    const fetchAppointments = async () => {
      if (!user?.id || !token) return;
      const URL = `https://usell.be/api/appointments/user/${user.id}/all/`;
      const options = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error("HTTP ERROR! STATUS: " + response.status);
        const data = await response.json();
        setAppointments(data);
      } catch (error) {
        console.error("Failed to fetch appointments:", error);
      }
    };

    fetchAppointments();
  }, [user, token]);

  useEffect(() => {
    if (!user?.id || !token) return;
    if (selectedAppointment) {
      const fetchAndValidateSlots = async (appointmentId) => {
        const slotsURL = `https://usell.be/api/slots/?appointment_id=${appointmentId}`;
        const validateURL = `https://usell.be/api/appointments/${appointmentId}/validate-timeslots/`;

        const options = {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };

        try {
          const response = await fetch(slotsURL, options);
          if (!response.ok) throw new Error("Error fetching slots");
          const data = await response.json();

          if (isSeller) {
            const now = new Date();

            const allSlotsInPast =
              data.length > 0 &&
              data.every((slot) => new Date(slot.date_time) < now);

            if (data.length === 0 || allSlotsInPast) {
              await fetch(validateURL, options);

              const newResponse = await fetch(slotsURL, options);
              if (!newResponse.ok) throw new Error("Error fetching slots");
              const newData = await newResponse.json();
              setSlots(newData);
            } else {
              setSlots(data);
            }
          } else {
            const buyerSlots = data.filter(
              (slot) => slot.selected_slot_seller === true
            );
            setSlots(buyerSlots);
          }
        } catch (error) {
          console.error("Error fetching slots:", error);
        }
      };

      fetchAndValidateSlots(selectedAppointment.id);
    }
  }, [selectedAppointment, isSeller, user, token]);

  const handleSlotSelect = (slotId) => {
    if (isSeller) {
      setSelectedSlots((prevSelectedSlots) => {
        if (prevSelectedSlots.includes(slotId)) {
          return prevSelectedSlots.filter((id) => id !== slotId);
        }
        if (prevSelectedSlots.length < 5) {
          return [...prevSelectedSlots, slotId];
        }
        return prevSelectedSlots;
      });
    } else {
      setSelectedSlot(slotId === selectedSlot ? null : slotId);
    }
  };

  const submitSlots = async () => {
    if (!selectedAppointment || selectedSlots.length !== 5) {
      alert("You must select exactly 5 slots to submit.");
      return;
    }

    const selectedSlotDates = selectedSlots.map((slotId) => {
      const slot = slots.find((s) => s.id === slotId);
      return new Date(slot.date_time).toISOString();
    });

    const URL = `https://usell.be/api/appointments/${selectedAppointment.id}/submit_slots/`;
    const token = apiAccessToken;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        slots: selectedSlotDates,
      }),
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok)
        throw new Error("Het bevestigen van een tijdstip is mislukt.");

      navigate("/dashboard/afspraken");
    } catch (error) {
      console.error("Het bevestigen van een tijdstip is mislukt.:", error);
    }
  };

  const confirmSlot = async () => {
    if (!selectedSlot) return;

    const URL = `https://usell.be/api/slots/confirm/${selectedSlot}/`;
    const token = apiAccessToken;
    const options = {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) {
        const errorData = await response.json();
        setErrorMessage(
          errorData.error ||
            "Het bevestigen van een tijdstip is mislukt. Gelieve uw betaling te vervolledigen en vervolgens opnieuw te proberen"
        );
        return;
      }

      const data = await response.json();
      setSelectedAppointment({
        ...selectedAppointment,
        appointment_date: data.appointment_date,
        status: "Accepted",
      });

      navigate("/dashboard/afspraken");
    } catch (error) {
      console.error("Failed to confirm slot:", error);
      setErrorMessage("An unexpected error occurred.");
    }
  };

  const declineSlots = async () => {
    if (!selectedAppointment) return;

    const URL = `https://usell.be/api/appointments/${selectedAppointment.id}/reset/`;
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${apiAccessToken}`,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(URL, options);
      if (!response.ok) throw new Error("Failed to reset appointment");
      navigate(`/dashboard/afspraken`);
    } catch (error) {
      console.error("Error resetting appointment:", error);
    }
  };

  const renderHourlySlots = () => {
    if (!isSeller && slots.length === 0) {
      return <p>{t("agenda11")}</p>;
    }

    return (
      <motion.div
        className="hourly-slots"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {slots.map((slot, index) => (
          <motion.button
            key={slot.id}
            className={`slot ${
              isSeller && selectedSlots.includes(slot.id) ? "selected" : ""
            } ${!isSeller && selectedSlot === slot.id ? "selected" : ""}`}
            onClick={() => handleSlotSelect(slot.id)}
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{
              delay: index * 0.2,
              type: "spring",
              stiffness: 300,
            }}
            whileHover={{ scale: 1.05 }}
          >
            {new Date(slot.date_time).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
            <br />
            {new Date(slot.date_time).toLocaleDateString()}
          </motion.button>
        ))}
      </motion.div>
    );
  };

  return (
    <>
      <UserNavigation />
      <motion.div
        className={"agenda-hero"}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className={"agenda-background"}></div>
        <Container
          className={"content content-padding-top-lg content-padding-bottom-lg"}
        >
          <motion.h1
            className={"agenda-title"}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
          >
            {dynamicTitle}
          </motion.h1>
        </Container>
      </motion.div>
      <Container className={"content agenda-container"}>
        <Row>
          <DropdownButton className={"button-centraal"} title={t("agenda2")}>
            {appointments
              .filter(
                (app) =>
                  app.status !== "ACCEPTED" &&
                  app.status !== "COMPLETED" &&
                  app.status !== "Suspended"
              )
              .map((app) => (
                <Dropdown.Item
                  key={app.id}
                  onClick={() => setSelectedAppointment(app)}
                >
                  {t("agenda3")} #{app.id} -{" "}
                  {app.owner_region || t("no_region")} - {app.auction_car.name}{" "}
                  {app.auction_car.model} {app.auction_car.generation}{" "}
                  {app.auction_car.series}
                </Dropdown.Item>
              ))}
          </DropdownButton>
        </Row>

        {errorMessage && (
          <Alert
            variant="danger"
            onClose={() => setErrorMessage("")}
            dismissible
          >
            {errorMessage}
          </Alert>
        )}

        {selectedAppointment && (
          <Row>
            <BelgiumMap selectedRegion={selectedAppointment.owner_region} />
            {isSeller ? (
              <>
                <h2>{t("agenda4")}</h2>
                {renderHourlySlots()}
                <Button
                  onClick={submitSlots}
                  disabled={selectedSlots.length !== 5}
                >
                  {t("agenda5")}
                </Button>
              </>
            ) : (
              <>
                <h2>{t("agenda6")}</h2>
                {renderHourlySlots()}
                {slots.length > 0 && (
                  <Row className="mt-3">
                    <Col>
                      <Button onClick={confirmSlot} disabled={!selectedSlot}>
                        {t("agenda7")}
                      </Button>
                      <Button onClick={declineSlots}>{t("agenda8")}</Button>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Row>
        )}
        {selectedAppointment && selectedAppointment.status === "Accepted" && (
          <Row>
            <h3>{t("agenda9")}</h3>
            <p>
              {t("agenda10")}{" "}
              {new Date(selectedAppointment.appointment_date).toLocaleString()}.
            </p>
          </Row>
        )}
      </Container>
    </>
  );
}
