import React from "react";
import { Container, Card } from "react-bootstrap";
import { UserNavigation } from "../../../components/UserNavigation";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import deniedAnimation from "../../../lotties/denied.json";

export function DeniedOnboardingPage() {
  const { t } = useTranslation();

  return (
    <>
      <UserNavigation />
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <motion.div
          className="text-center"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Card className="p-5 shadow-lg border-0">
            <Lottie
              animationData={deniedAnimation}
              loop={false}
              style={{ height: "150px" }}
            />
            <motion.h1
              className="mb-3 text-danger"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.2 }}
            >
              {t("onboarding3")}
            </motion.h1>
            <motion.p
              className="mb-4"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.4 }}
            >
              {t("onboarding4")}
            </motion.p>
          </Card>
        </motion.div>
      </Container>
    </>
  );
}
