import React, { useEffect, useState, useContext } from 'react';
import { Container, Form, Table, Button, Modal } from 'react-bootstrap';
import { AdminNavbar } from "../../components/AdminNav";
import { AuthContext } from "../../contexts/AuthContext";

const AdminDisabledAuctionsPage = () => {

    const [auctions, setAuctions] = useState(null);
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { apiAccessToken } = useContext(AuthContext);

    useEffect(() => {
        const URL = "https://usell.be/api/disabled-auctions/";
        const token = apiAccessToken;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setAuctions(data);
            } catch (error) {
                console.error(error);
            }
        }
    }, [apiAccessToken]);

    if (!auctions) return "Loading";

    return (
        <>
            <AdminNavbar />
            <AdminManageAuction auctions={auctions} setAuctions={setAuctions} setSelectedAuction={setSelectedAuction} selectedAuction={selectedAuction} showModal={showModal} setShowModal={setShowModal} />
        </>
    );
};

const EmailSearch = (props) => {
    const { auctions, handleEdit, selectedAuction, showModal, setShowModal } = props;
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);



    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setQuery(searchTerm);
        if (searchTerm.length > 2) {
            setResults(auctions.filter(auction => auction.name.toLowerCase().includes(searchTerm)));
        } else {
            setResults([]);
        }
    };

    return (
        <>
            <Container>
                <Form className="my-3">
                    <Form.Group controlId="emailSearch">
                        <Form.Label>Zoek op naam</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Geef naam in"
                            value={query}
                            onChange={handleSearch}
                        />
                    </Form.Group>
                </Form>
                <Table striped bordered responsive hover>
                    <thead>
                        <tr>
                            <th>Naam</th>
                            <th>Beschrijving</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {query.length > 2 ? results.map(auction => (
                            <tr key={auction.id}>
                                <td>{auction.name}</td>
                                <td>{auction.description}</td>
                                <td>{auction.status}</td>
                                <td>
                                    <Button variant="outline-dark" className={"border-0"} size="sm" onClick={() => handleEdit(auction)}><i className={"bi bi-pencil"}></i></Button>
                                </td>
                            </tr>
                        )) : auctions.map(auction => (
                            <tr key={auction.id}>
                                <td>{auction.name}</td>
                                <td>{auction.description}</td>
                                <td>{auction.status}</td>
                                <td>
                                    <Button variant="outline-dark" className={"border-0"} size="sm" onClick={() => handleEdit(auction)}> <i className={"bi bi-pencil"}></i> </Button>

                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            {selectedAuction ? (
                <Modal show={showModal} size={"lg"} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Veiling Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Naam:</h4>
                        <p>{selectedAuction.name}</p>

                        <h4>Beschrijving:</h4>
                        <p>{selectedAuction.description}</p>

                        <h4>Startdatum:</h4>
                        <p>{new Date(selectedAuction.start_date).toLocaleString()}</p>

                        <h4>Einddatum:</h4>
                        <p>{new Date(selectedAuction.end_date).toLocaleString()}</p>

                        <h4>Status:</h4>
                        <p>{selectedAuction.status}</p>

                        <h4>Address</h4>
                        <p>{selectedAuction.address.street} {selectedAuction.address.house_number} {selectedAuction.address.zip_code} {selectedAuction.address.city}</p>

                        <h4>Eigenaar:</h4>
                        <p>{selectedAuction.owner.first_name} {selectedAuction.owner.last_name}</p>

                        <h4>Telefoonnummer:</h4>
                        <p>{selectedAuction.address.phone_number}</p>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => setShowModal(false)}>
                            Verwijder veiling
                        </Button>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Sluit
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : ""}
        </>
    );
};


const AdminManageAuction = ({ auctions, setAuctions }) => {
    const [selectedAuction, setSelectedAuction] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { apiAccessToken } = useContext(AuthContext);

    const handleEdit = auction => {
        setSelectedAuction(auction);
        setShowModal(true);
    };

    const handleDelete = async auction => {
        const URL = `https://usell.be/api/admin/delete-auction/${auction.id}/`;

        try {
            const response = await fetch(URL, {
                method: "DELETE",
                headers: {
                    authorization: `Bearer ${apiAccessToken}`,
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
            setShowModal(false);
            setAuctions(prev => prev.filter(a => a.id !== auction.id));
        } catch (error) {
            console.error("Error deleting auction:", error);
        }
    };

    return (
        <Container>
            <EmailSearch auctions={auctions} handleEdit={handleEdit} handleDelete={handleDelete} />
            {selectedAuction && (
                <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Veiling Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <h4>Naam:</h4>
                            <p>{selectedAuction.name}</p>
                            <h4>Beschrijving:</h4>
                            <p>{selectedAuction.description}</p>
                            <h4>Startdatum:</h4>
                            <p>{new Date(selectedAuction.start_date).toLocaleString()}</p>
                            <h4>Einddatum:</h4>
                            <p>{new Date(selectedAuction.end_date).toLocaleString()}</p>
                            <h4>Status:</h4>
                            <p>{selectedAuction.status}</p>
                            <h4>Adres:</h4>
                            <p>{selectedAuction.address.street} {selectedAuction.address.house_number}, {selectedAuction.address.zip_code} {selectedAuction.address.city}</p>
                            <h4>Eigenaar:</h4>
                            <p>{selectedAuction.owner.first_name} {selectedAuction.owner.last_name}</p>
                            <h4>Telefoonnummer:</h4>
                            <p>{selectedAuction.address.phone_number}</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={() => handleDelete(selectedAuction)}>
                            Verwijder veiling
                        </Button>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Sluit
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Container>
    );
};

export { AdminDisabledAuctionsPage };
