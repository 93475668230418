import { Card, Col, Row } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { FaClock, FaEye } from "react-icons/fa";
import { motion } from "framer-motion";

export function StockCard(props) {
  const {
    title,
    buildyear,
    mileage,
    fuelType,
    image,
    stockItemId,
    visitCount,
  } = props;
  const { apiAccessToken } = useContext(AuthContext);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [stockImage, setStockImage] = useState(image);
  const { t } = useTranslation();
  const [localVisitCount, setLocalVisitCount] = useState(visitCount);
  
  useEffect(() => {
    const URL = `https://usell.be/api/stock/${stockItemId}/`;
    const token = apiAccessToken;
    const options = {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    };

    const fetchStockDetails = async () => {
      try {
        const response = await fetch(URL, options);
        if (!response.ok)
          throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
        const stock = await response.json();

        const endTime = moment(stock.end_date);
        const now = moment();
        const remainingTime = endTime.diff(now, "seconds");

        setTimeRemaining(remainingTime > 0 ? remainingTime : 0);

        if (stock.images && stock.images.length > 0) {
          setStockImage(stock.images[0].url);
        }

        setLocalVisitCount(stock.visit_count || stock.visits);
      } catch (error) {
        console.error("Failed to fetch stock details:", error);
      }
    };

    fetchStockDetails();
  }, [stockItemId, apiAccessToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining]);

  function formatTimeRemaining(timeRemaining) {
    if (timeRemaining === 0) {
      return t("car2");
    }

    const duration = moment.duration(timeRemaining, "seconds");
    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <Card>
        <div style={{ position: "relative" }}>
          <Card.Img
            className={"carcard-img"}
            variant="top"
            src={stockImage || image}
          />
        </div>
        <Card.Body>
          <Card.Title>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{title}</span>
              <span style={{ display: "flex", alignItems: "center" }}>
                <FaClock style={{ marginRight: "5px" }} />
                {formatTimeRemaining(timeRemaining)}
              </span>
            </div>
          </Card.Title>
          <Row className="mobiel-card">
            <Col sm={4} className={"car-details"}>
              <svg
                stroke={"currentColor"}
                fill={"currentColor"}
                strokeWidth={"0"}
                viewBox={"0 0 512 512"}
                color={"#93c5fd"}
                className={"mb-1"}
                height={"30px"}
                width={"30px"}
                xmlns={"http://www.w3.org/2000/svg"}
                style={{ color: "rgb(147, 197, 253)" }}
              >
                <path
                  d={
                    "M384 64l.387 256H368l-96-128-16 128-96-128-16 128-96-128-16 128v160h448V64h-32v256h-32V64h-32zM64 352h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zM64 416h48v32H64v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32zm80 0h48v32h-48v-32z"
                  }
                ></path>
              </svg>
              <br />
              <p>{buildyear}</p>
            </Col>
            <Col sm={4} className={"car-details"}>
              <svg
                stroke={"currentColor"}
                fill={"currentColor"}
                strokeWidth={"0"}
                viewBox={"0 0 576 512"}
                color={"#93c5fd"}
                className={"mb-1"}
                height={"30px"}
                width={"30px"}
                xmlns={"http://www.w3.org/2000/svg"}
                style={{ color: "rgb(147, 197, 253)" }}
              >
                <path
                  d={
                    "M573.19 402.67l-139.79-320C428.43 71.29 417.6 64 405.68 64h-97.59l2.45 23.16c.5 4.72-3.21 8.84-7.96 8.84h-29.16c-4.75 0-8.46-4.12-7.96-8.84L267.91 64h-97.59c-11.93 0-22.76 7.29-27.73 18.67L2.8 402.67C-6.45 423.86 8.31 448 30.54 448h196.84l10.31-97.68c.86-8.14 7.72-14.32 15.91-14.32h68.8c8.19 0 15.05 6.18 15.91 14.32L348.62 448h196.84c22.23 0 36.99-24.14 27.73-45.33zM260.4 135.16a8 8 0 0 1 7.96-7.16h39.29c4.09 0 7.53 3.09 7.96 7.16l4.6 43.58c.75 7.09-4.81 13.26-11.93 13.26h-40.54c-7.13 0-12.68-6.17-11.93-13.26l4.59-43.58zM315.64 304h-55.29c-9.5 0-16.91-8.23-15.91-17.68l5.07-48c.86-8.14 7.72-14.32 15.91-14.32h45.15c8.19 0 15.05 6.18 15.91 14.32l5.07 48c1 9.45-6.41 17.68-15.91 17.68z"
                  }
                ></path>
              </svg>
              <br />
              <p>{mileage} KM</p>
            </Col>
            <Col sm={4} className={"car-details"}>
              <svg
                stroke={"currentColor"}
                fill={"currentColor"}
                strokeWidth={"0"}
                viewBox={"0 0 512 512"}
                color={"#93c5fd"}
                className={"mb-1"}
                height={"30px"}
                width={"30px"}
                xmlns={"http://www.w3.org/2000/svg"}
                style={{ color: "rgb(147, 197, 253)" }}
              >
                <path
                  d={
                    "M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"
                  }
                ></path>
              </svg>
              <br />
              <p>{fuelType}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <FaEye /> {localVisitCount}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </motion.div>
  );
}
