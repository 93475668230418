import React, { useEffect, useState, useContext } from "react";
import { Container, Card, Button } from "react-bootstrap";
import { UserNavigation } from "../../../components/UserNavigation";
import { AuthContext } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import successAnimation from "../../../lotties/succes.json";

export function SuccessOnboardingPage({ token }) {
  const [notificationCreated, setNotificationCreated] = useState(false);
  const [buttonHref, setButtonHref] = useState("");
  const [buttonText, setButtonText] = useState("");
  const { userMetadata } = useContext(AuthContext);
  const user = userMetadata;
  const { t } = useTranslation();

  useEffect(() => {
    const createNotification = async () => {
      if (!user?.id || !token || notificationCreated) return;

      const URL = `https://usell.be/api/notifications/onboarding`;
      const options = {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message:
            "Je hebt succesvol geregistreerd en kan nu betalingen ontvangen of biedingen plaatsen. Ga naar je biedingen en probeer opnieuw een bod te accepteren of plaats een nieuw bod op een kavel.",
          notification_type: "info",
        }),
      };

      try {
        const response = await fetch(URL, options);
        if (!response.ok) throw new Error(`HTTP ERROR! STATUS: ${response.status}`);
        setNotificationCreated(true);
      } catch (error) {
        console.error("Error creating notification:", error);
      }
    };

    createNotification();
  }, [user?.id, token, notificationCreated, user]);

  useEffect(() => {
    if (user?.type === "seller") {
      setButtonHref("https://usell.be/dashboard/biedingen");
      setButtonText("Ga naar Mijn Biedingen");
    } else {
      setButtonHref("https://usell.be/veilingen");
      setButtonText("Ga naar Veilingen");
    }
  }, [user?.type]);

  return (
    <>
      <UserNavigation />
      <Container className="d-flex justify-content-center align-items-center vh-100">
        <motion.div 
          className="text-center"
          initial={{ opacity: 0, y: -50 }} 
          animate={{ opacity: 1, y: 0 }} 
          transition={{ duration: 1 }}
        >
          <Card className="p-5 shadow-lg border-0">
            <Lottie animationData={successAnimation} loop={false} style={{ height: "150px" }} />
            <motion.h1 
              className="mb-3 text-success"
              initial={{ opacity: 0 }} 
              animate={{ opacity: 1 }} 
              transition={{ duration: 1, delay: 0.2 }}
            >
              {t("onboarding")}
            </motion.h1>
            <motion.p 
              className="mb-4"
              initial={{ opacity: 0, y: 10 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ duration: 1, delay: 0.4 }}
            >
              {t("onboarding1")}
            </motion.p>
            <motion.p 
              className="mb-4"
              initial={{ opacity: 0, y: 10 }} 
              animate={{ opacity: 1, y: 0 }} 
              transition={{ duration: 1, delay: 0.6 }}
            >
              {t("onboarding2")}
            </motion.p>
            <motion.a
              href={buttonHref}
              className="btn btn-primary btn-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {buttonText}
            </motion.a>
          </Card>
        </motion.div>
      </Container>
    </>
  );
}