import React, { useEffect, useState, useContext } from 'react';
import { Container, Form, Table, Button, Modal, Col, Row } from 'react-bootstrap';
import { AdminNavbar } from "../../components/AdminNav";
import { AuthContext } from "../../contexts/AuthContext";

const AdminManageUserPage = () => {
    const [users, setUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { apiAccessToken } = useContext(AuthContext);

    useEffect(() => {
        const URL = "https://usell.be/api/users/";
        const token = apiAccessToken;
        const options = {
            method: "GET",
            headers: {
                authorization: `Bearer ${token}`,
                Accept: "application/json",
            },
        };

        fetchData();

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const data = await response.json();
                setUsers(data);
            } catch (error) {
                console.error(error);
            }
        }
    }, [apiAccessToken]);

    if (!users) return "Loading";

    return (
        <>
            <AdminNavbar />
            <AdminManageUsers users={users} setUsers={setUsers} setSelectedUser={setSelectedUser} selectedUser={selectedUser} showModal={showModal} setShowModal={setShowModal} />
        </>
    );
};

const EmailSearch = (props) => {
    const { apiAccessToken } = useContext(AuthContext);
    const { users, setUsers, handleEdit, handleDelete, selectedUser, setSelectedUser, showModal, setShowModal } = props;
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);

    const handleSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setQuery(searchTerm);
        if (searchTerm.length > 2) {
            setResults(users.filter(user => user.email.toLowerCase().includes(searchTerm)));
        } else {
            setResults([]);
        }
    };

    const toggleUserStatus = (user) => {
        const URL = user.status
            ? `https://usell.be/api/admin/blacklist-user/${user.id}/`
            : `https://usell.be/api/admin/whitelist-user/${user.id}/`;
        const token = apiAccessToken;
        const options = {
            method: "PATCH",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        async function fetchData() {
            try {
                const response = await fetch(URL, options);
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                const updatedUser = await response.json();
                setSelectedUser(updatedUser);

                setUsers((prevUsers) =>
                    prevUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u))
                );
            } catch (error) {
                console.error(error);
            }
        }

        fetchData();
    };

    return (
        <>
            <Container>
                <Form className="my-3">
                    <Form.Group controlId="emailSearch">
                        <Form.Label>Search by Email</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter email"
                            value={query}
                            onChange={handleSearch}
                        />
                    </Form.Group>
                </Form>
                <Table striped bordered hover responsive className='tabel-users-admin'>
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {query.length > 2 ? results.map(user => (
                            <tr key={user.email}>
                                <td>{user.email}</td>
                                <td>{user.first_name} {user.last_name}</td>
                                <td>{user.status ? "Actief" : "Inactief"}</td>
                                <td>{user.type}</td>
                                <td>
                                    <Button variant="outline-dark" className={"border-0"} size="sm" onClick={() => handleEdit(user)}><i className={"bi bi-pencil"}></i></Button>
                                    <Button variant="danger" size="sm" onClick={() => handleDelete(user)}>Verwijder</Button>
                                </td>
                            </tr>
                        )) : users.map(user => (
                            <tr key={user.email}>
                                <td>{user.email}</td>
                                <td>{user.first_name} {user.last_name}</td>
                                <td>{user.status ? "Actief" : "Inactief"}</td>
                                <td>{user.type}</td>
                                <td>
                                    <Button variant="outline-dark" className={"border-0"} size="sm" onClick={() => handleEdit(user)}> <i className={"bi bi-pencil"}></i> </Button>
                                    <Button variant="danger" size="sm" onClick={() => handleDelete(user)}>Verwijder</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Container>

            {selectedUser ? (
                <Modal show={showModal} size={"lg"} onHide={() => setShowModal(false)}>
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <span className={"mb-0"}>{selectedUser.first_name} {selectedUser.last_name}</span>
                                {selectedUser.status ? "" : <span className={"modal-subtitle"}> (BLACKLISTED)</span>}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group className={"mb-2"}>
                                <Form.Label className={"mb-0"}>Email</Form.Label>
                                <Form.Control type={"text"} value={selectedUser.email} disabled></Form.Control>
                            </Form.Group>
                            <Form.Group className={"mb-2"}>
                                <Form.Label className={"mb-0"}>Website</Form.Label>
                                <Form.Control type={"text"} value={selectedUser.website} disabled></Form.Control>
                            </Form.Group>
                            <Form.Group className={"mb-2"}>
                                <Form.Label className={"mb-0"}>Geboortedatum</Form.Label>
                                <Form.Control type={"date"} value={selectedUser.birthdate} disabled></Form.Control>
                            </Form.Group>
                            <h5 className={"mb-0"}>Adres</h5>
                            <Row className={"mb-0"}>
                                <Col sm={9}>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Straat</Form.Label>
                                        <Form.Control type={"text"} value={selectedUser.address.street} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={3}>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Huisnummer</Form.Label>
                                        <Form.Control type={"text"} value={selectedUser.address.house_number} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-0"}>
                                <Col sm={3}>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Postcode</Form.Label>
                                        <Form.Control type={"number"} value={selectedUser.address.zip_code} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col sm={9}>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Stad</Form.Label>
                                        <Form.Control type={"text"} value={selectedUser.address.city} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Provincie</Form.Label>
                                        <Form.Control type={"text"} value={selectedUser.address.province} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Land</Form.Label>
                                        <Form.Control type={"text"} value={selectedUser.address.country} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className={"mb-2"}>
                                        <Form.Label className={"mb-0"}>Telefoonnummer</Form.Label>
                                        <Form.Control type={"text"} value={selectedUser?.address?.phone_number} disabled></Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="secondary"
                                onClick={() => toggleUserStatus(selectedUser)}
                            >
                                {selectedUser.status ? "Blacklist" : "Whitelist"}
                            </Button>
                            <Button variant="danger" onClick={() => handleDelete(selectedUser)}>
                                Verwijder gebruiker
                            </Button>
                            <Button variant="primary" onClick={() => setShowModal(false)}>
                                Sluit
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            ) : ""}
        </>
    );
};

const AdminManageUsers = (props) => {
    const { users, setUsers, selectedUser, setSelectedUser, showModal, setShowModal } = props;
    const { apiAccessToken } = useContext(AuthContext);


    const handleEdit = user => {
        setSelectedUser(user);
        setShowModal(true);
    };

    const handleDelete = (user) => {
        const URL = `https://usell.be/api/admin/delete-user/${user.id}/`;
        const token = apiAccessToken;

        const options = {
            method: "DELETE",
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        fetch(URL, options)
            .then(response => {
                if (!response.ok) throw new Error("HTTP ERROR! STATUS: " + response.status);
                setUsers(users.filter(u => u.id !== user.id));
                setShowModal(false);
                console.log("User successfully deleted from backend.");
            })
            .catch(error => {
                console.error("There was an error deleting the user:", error);
            });
    };


    return (
        <Container>
            <EmailSearch handleEdit={handleEdit} handleDelete={handleDelete} users={users} setUsers={setUsers} selectedUser={selectedUser} setSelectedUser={setSelectedUser} showModal={showModal} setShowModal={setShowModal} />
        </Container>
    );
};

export { AdminManageUserPage };