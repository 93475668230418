import React, { useContext, useState } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import { Dropdown, Badge } from 'react-bootstrap';
import { Bell } from 'react-bootstrap-icons';
import { motion } from 'framer-motion';

export const Notification = () => {
    const { notifications, markAsRead } = useContext(NotificationContext);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const toggleDropdown = () => setShowDropdown(!showDropdown);
    const toggleShowAll = () => setShowAll(!showAll);
    const displayedNotifications = showAll ? notifications : notifications.slice(0, 5);

    return (
        <Dropdown show={showDropdown} onToggle={toggleDropdown} align="end">
            <Dropdown.Toggle variant="light" className="notification-toggle">
                <Bell size={20} />
                {notifications.length > 0 && (
                    <Badge bg="danger" pill>{notifications.length}</Badge>
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu className="notification-dropdown-menu">
                {displayedNotifications.length === 0 ? (
                    <Dropdown.Item disabled className="text-muted">
                        No new notifications
                    </Dropdown.Item>
                ) : (
                    displayedNotifications.map((notification) => (
                        <motion.div
                            key={notification.id}
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 20 }}
                            transition={{ duration: 0.3 }}
                            className='notification-container'
                        >
                            <Dropdown.Item
                                className="notification-item"
                                onClick={() => markAsRead(notification.id)}
                            >
                                {notification.message}
                            </Dropdown.Item>
                        </motion.div>
                    ))
                )}
                {notifications.length > 5 && !showAll && (
                    <Dropdown.Item as="button" className="text-primary" onClick={toggleShowAll}>
                        Read All
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};